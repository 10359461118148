import regex from '@/data/regex';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            validationTitle: null,
            validationErrors: {},
            formValidation: null
        };
    },

    computed: {
        ...mapGetters(['i18n']),

        zipRules() {
            return [v => regex.zipCodeGermany.test(v) || this.i18n.FORM_VALIDATION.ZIP];
        },

        emailRules() {
            return [v => regex.email.test(v) || this.i18n.FORM_VALIDATION.EMAIL];
        },

        membershipNumberRules() {
            return [v => regex.membershipNumber.test(v) || this.i18n.FORM_VALIDATION.BWS_MEMBERSHIP];
        },

        seminarNumberRules() {
            return [v => regex.seminarNumber.test(v) || this.i18n.FORM_VALIDATION.SEMINAR_NUMBER];
        },

        requiredRules() {
            return [v => !!v || this.i18n.FORM_VALIDATION.REQUIRED];
        },

        mfaTokenRules() {
            return [v => regex.mfaToken.test(v) || this.i18n.FORM_VALIDATION.MFA];
        },

        passwordRules() {
            return [v => regex.passwordPolicy.test(v) || this.i18n.FORM_VALIDATION.PASSWORD_ALL];
        },

        urlRules() {
            return [v => regex.url.test(v) || this.i18n.FORM_VALIDATION.URL];
        }
    },

    methods: {
        maxFileSize(maxFileSize) {
            return [
                v => {
                    if (v) {
                        if (v.size > parseInt(maxFileSize * 1000, 10)) {
                            return 'Datei zu groß';
                        }
                    }

                    return true;
                }
            ];
        },

        maxChars(maxValue) {
            return [
                v => {
                    if (maxValue === 0) return true;
                    if (!v) return true;

                    return v.length <= parseInt(maxValue, 10) || 'Angabe ist zu lang';
                }
            ];
        },

        setValidationErrors(err, showMessage = true) {
            if (err.response && err.response.status !== 500 && err.response.status !== 401) {
                const { data } = err.response;
                const { errors } = data;
                this.validationErrors = errors;
            }
        },

        hasErrors(key) {
            if (!isEmpty(this.validationErrors) && key in this.validationErrors) {
                return this.validationErrors[key];
            }

            return null;
        },

        getErrors(key) {
            if (!isEmpty(this.validationErrors) && key in this.validationErrors) {
                return this.validationErrors[key];
            }

            return null;
        },

        validate() {
            this.$refs.form.validate();
        },

        reset() {
            this.$refs.form.reset();
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        }
    }
};
