<template>
    <span class="mb-0">{{ dateLabel }}</span>
</template>

<script>
import dateFilter from '@/mixins/dateFilter';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { mapGetters } from 'vuex';

export default {
    name: 'BaseSeminarDate',

    mixins: [dateFilter],

    props: {
        startAt: {
            type: [Date, String],
            required: true
        },

        endAt: {
            type: [Date, String],
            required: false,
            default: null
        }
    },

    computed: {
        ...mapGetters(['i18n']),

        itsChristmas() {
            dayjs.extend(objectSupport);
            const christmas = dayjs({ month: 11, day: 24 }).format('DD.MM');
            const startAtOnChristmas = dayjs(this.startAt).format('DD.MM') === christmas;
            const endAtOnChristmas = dayjs(this.endAt).format('DD.MM') === christmas;

            return startAtOnChristmas || endAtOnChristmas;
        },

        dateLabel() {
            if (this.itsChristmas) return this.i18n.BASE.SEMINAR.DATE.TBA;
            if (!this.endAt) return this.dateShort(this.startAt);

            return `${this.dateShorter(this.startAt)} - ${this.dateShort(this.endAt)}`;
        }
    }
};
</script>
