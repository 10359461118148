import { toNumber } from 'lodash';
import dayjs from 'dayjs';

/**
 * Seperate string types into string, boolean and integer
 *
 * @param {string} value
 * @returns {string|boolean|number}
 * @author Dominik Niemann <dominik@polargold.de>
 */
const typeCastHelper = (value: any): boolean | string | number => {
    const isNumber = new RegExp(/[+-]?([0-9]*[.])?[0-9]+/m);
    const hasLetters = new RegExp(/(([A-Z].*[0-9])|([0-9].*[A-Z]))/m);
    const isDate = dayjs(value).isValid();

    if (value === 'true') {
        return true;
    }

    if (value === 'false') {
        return false;
    }

    if (isNumber.test(value) && !hasLetters.test(value) && !isDate) {
        return toNumber(value);
    }

    return value;
};

export default typeCastHelper;
