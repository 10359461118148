export default {
    zipCodeGermany: new RegExp(/^[0-9]{4,6}$/),
    email: new RegExp(/.+@.+\..+/),
    seminarNumber: new RegExp(/^BWS-(\d{3})-(\d{2})(\d{2})(\d{2})-(\d{2})$/),
    mfaToken: new RegExp(/^\d{6}$/u),
    passwordPolicy: new RegExp(/(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/m),
    passwordLetters: new RegExp(/(?=.*?[A-Z]).*?[a-z].*/m),
    passwordNumbers: new RegExp(/(?=.*?[0-9])/m),
    passwordSymbols: new RegExp(/(?=.*?[^\w\s])/m),
    passwordLength: new RegExp(/(?=.{12,})/m),
    membershipNumber: new RegExp(/^[0-9]{7}$/),
    whiteSpace: new RegExp(/\s/g),
    baseComponent: new RegExp(/Base[A-Z]\w+\.(vue|js)$/)
};
