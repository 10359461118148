











export default {
    name: 'IconFont',

    props: {
        iconName: {
            type: String,
            required: true
        },

        marginLeft: {
            type: String
        },

        iconDisplay: {
            type: String,
            default: 'block'
        },

        iconSize: {
            type: String,
            default: null
        },

        iconColor: {
            type: String,
            default: 'text-gk-black'
        }
    },

    computed: {
        classNames() {
            return [this.iconName, this.iconSize, this.iconColor].filter(className => !!className);
        }
    }
};
