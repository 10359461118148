import fetchFilterEntries from './actions/fetchFilterEntries';
import fetchAllFilterOptions from './actions/fetchAllFilterOptions';
import { State } from './types/index';
import { Region } from '@/types';

export const namespaced = true;

export const state: State = {
    categories: [],
    starterSeminarValues: [],
    locations: [],
    courseFormats: [],
    committeeTypes: [],
    speakers: [],
    regions: []
};

export const mutations = {
    ADD_CATEGORIES(state: State, categories: object[]): void {
        state.categories = categories;
    },

    ADD_REGIONS(state: State, regions: Region[]): void {
        state.regions = regions;
    },

    ADD_STARTER_SEMINAR_VALUES(state: State, starterSeminarValues: string[] = []): void {
        state.starterSeminarValues = starterSeminarValues;
    },

    ADD_COURSE_FORMATS(state: State, courseFormats: object[]): void {
        state.courseFormats = courseFormats;
    },

    ADD_COMMITTEE_TYPES(state: State, committeeTypes: object[] = []): void {
        state.committeeTypes = committeeTypes;
    },

    ADD_SPEAKERS(state: State, speakers: object[] = []): void {
        state.speakers = speakers;
    },

    ADD_LOCATIONS(state: State, locations: string[] = []): void {
        state.locations = locations;
    }
};

export const actions = {
    fetchFilterEntries,
    fetchAllFilterOptions
};

export const getters = {
    starterSeminarValues: (state: State): string[] => {
        return state.starterSeminarValues;
    },

    courseFormats: (state: State): string[] => {
        return state.courseFormats.map((item: { key }) => item.key);
    },

    filterOptions(state: State): { locations: object[] } {
        return {
            ...state,
            locations: state.locations.map(item => ({ text: item, value: item }))
        };
    }
};
