import { jumpToTop } from '@/helper/jumpToTop';

/**
 * Wrapper to apply filter
 *
 * @param dispatch
 * @param commit
 * @param callback
 * @param payload
 */
export default async ({ dispatch, commit }: any, { callback = null, payload = null } = {}): Promise<void> => {
    try {
        await dispatch(callback, payload);
        dispatch('seminarSearchResults/fetchSeminarResults', null, { root: true });
    } finally {
        commit('CHANGE_CURRENT_PAGE', 0);

        jumpToTop();
    }
};
