export const namespaced = true;

interface State {
    activeLayer2Item: string;
}

export const state: any = {
    activeLayer2Item: ''
};

export const mutations = {
    ADD_ACTIVE_LAYER2_ITEM(state: State, activeItemName: string): void {
        state.activeLayer2Item = activeItemName;
    }
};

export const actions = {
    addActiveLayer2Item({ commit }, activeItemName: string): void {
        commit('ADD_ACTIVE_LAYER2_ITEM', activeItemName);
    }
};

export const getters = {
    activeLayer2Item: (state: State) => state.activeLayer2Item
};
