export enum OvernightIdName {
    NoOvernight = 'Ohne Übernachtung',
    Overnight = 'Mit Übernachtung',
    OvernightPre = 'Mit Übernachtung + Vorübernachtung',
    OvernightPost = 'Mit Übernachtung + Nachübernachtung',
    OvernightPrePost = 'Mit Übernachtung + Vorübernachtung + Nachübernachtung'
}

export type OverNightIds =
    | 'Ohne Übernachtung'
    | 'Mit Übernachtung'
    | 'Mit Übernachtung + Vorübernachtung'
    | 'Mit Übernachtung + Nachübernachtung'
    | 'Mit Übernachtung + Vorübernachtung + Nachübernachtung';

export interface SeminarPrices {
    accommodationPrice: number;
    nightAfterAccommodationPrice: number;
    nightBeforeAccommodationPrice: number;
    cateringPrice: number;
}
