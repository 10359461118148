import SeminarService from '@/services/SeminarService';

/**
 * Fetch seminars
 *
 * @param commit
 * @param getters
 */
export default async ({ commit, rootGetters }: any): Promise<void> => {
    const { pageSize } = rootGetters['seminarSearchFilterValues/filterMeta'];
    const offset = rootGetters['seminarSearchFilterValues/currentOffset'];
    const {
        fullTextSearch,
        category,
        city,
        courseFormat,
        committeeType,
        speakers,
        starterSeminars,
        fromDate,
        toDate,
        locationRegionId
    } = rootGetters['seminarSearchFilterValues/filterValues'];

    try {
        const { data } = await SeminarService.getSeminars({
            fullTextSearch,
            category,
            city,
            courseFormat,
            committeeType,
            speakers,
            starterSeminars,
            fromDate,
            toDate,
            locationRegionId,
            pageSize,
            offset
        });

        await commit('ADD_SEMINARS', data.items);
        await commit('ADD_SEMINAR_COUNT', data.count);
        await commit('ADD_EVENTS_COUNT', data.eventsCount);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
