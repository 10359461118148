import { pull, isEmpty, uniq } from 'lodash';
import { Status } from '@/helper/status';
import { yearSpan } from '@/helper/date';
import applyFilter from './actions/applyFilter';
import addCategoryFilter from './actions/addCategoryFilter';
import addTextFilter from './actions/addTextFilter';
import addStarterSeminarFilter from './actions/addStarterSeminarFilter';
import addLocationFilter from './actions/addLocationFilter';
import addCommitteeTypeFilter from './actions/addCommitteeTypeFilter';
import addCourseFormatFilter from './actions/addCourseFormatFilter';
import addSpeakerFilter from './actions/addSpeakerFilter';
import filterAddStartDate from './actions/filterAddStartDate';
import filterAddEndDate from './actions/filterAddEndDate';
import filterAddDateRange from './actions/filterAddDateRange';
import resetFilter from './actions/resetFilter';
import applyReset from './actions/applyReset';
import applyOffset from './actions/applyOffset';
import toggleHiddenFilter from './actions/toggleHiddenFilter';
import changeCurrentPage from './actions/changeCurrentPage';
import {
    CommitteeTypeMutation,
    CourseFormatMutation,
    DateRange,
    GetterFilterValues,
    LocationNames,
    State
} from './types';
import { QueryFilterMutationPayload, Region, Category } from '@/types';
import addRegionFilter from '@/store/modules/seminarSearchFilterValues/actions/addRegionFilter';
import formatDateForPayload from '@/helper/formatDateForPayload';
import dayjs from 'dayjs';

export const namespaced = true;

const today = dayjs();
const todayInTwoYears = today.add(2, 'year');

export const state: State = {
    offset: 0,
    pageSize: 10,
    filterValues: {
        category: [],
        fullTextSearch: null,
        starterSeminars: [],
        locationNames: [],
        'e-learning': false,
        'presence-seminar': false,
        'blended-learning': false,
        BR: false,
        JAV: false,
        SBV: false,
        committeeType: [],
        speaker: [],
        regions: []
    },
    startDate: today.toISOString(),
    endDate: todayInTwoYears.toISOString(),
    activeFilter: [],
    status: Status.Init,
    activeQueries: [],
    showHiddenFilter: false,
    currentPage: 1
};

export const mutations = {
    CHANGE_STATUS(state: State, status: number): void {
        state.status = status;
    },

    FILTER_ADD_DATE_RANGE(state: State, dateRange: string[]): void {
        state.startDate = dateRange[0];
        state.endDate = dateRange[1];
    },

    QUERY_FILTER_ADD_START_DATE(state: State, payload: QueryFilterMutationPayload): void {
        if (typeof payload.value === 'string') {
            state.startDate = dayjs(payload.value).toISOString();
        }
    },

    QUERY_FILTER_ADD_END_DATE(state: State, payload: QueryFilterMutationPayload): void {
        if (typeof payload.value === 'string') {
            state.endDate = dayjs(payload.value).toISOString();
        }
    },

    FILTER_ADD_CATEGORY(state: State, category: Category[]): void {
        state.filterValues.category = category;
    },

    FILTER_ADD_REGION(state: State, regionNames: Region[]): void {
        state.filterValues.regions = regionNames;
    },

    QUERY_FILTER_ADD_CATEGORY(state: State, payload: QueryFilterMutationPayload): void {
        const name = payload.value;

        if (typeof name === 'string') {
            state.filterValues.category = [{ colour: '#FFFFFF', name, id: 0 }];
        }
    },

    FILTER_ADD_SEARCH_TERM(state: State, searchTerm: string = ''): void {
        state.filterValues.fullTextSearch = searchTerm;
    },

    FILTER_ADD_STARTER_SEMINARS(state: State, starterSeminars: string[] = []): void {
        state.filterValues.starterSeminars = starterSeminars;
    },

    FILTER_ADD_LOCATION(state: State, locationNames: LocationNames[]): void {
        state.filterValues.locationNames = locationNames;
    },

    QUERY_FILTER_ADD_LOCATION(state: State, payload: QueryFilterMutationPayload): void {
        const name = payload.value;

        if (typeof name === 'string') {
            state.filterValues.locationNames = [{ text: name, value: name }];
        }
    },

    FILTER_ADD_COURSE_FORMAT(state: State, payload: CourseFormatMutation): void {
        state.filterValues[payload.key] = payload.value;
    },

    FILTER_ADD_COMMITTEE_TYPE(state: State, payload: CommitteeTypeMutation): void {
        state.filterValues[payload.key] = payload.value;
    },

    FILTER_REMOVE_COMMITTEE_TYPE(state: State, committeeType: String = ''): void {
        pull(state.filterValues.committeeType, committeeType);
    },

    FILTER_ADD_SPEAKER(state: State, speaker: object[]): void {
        state.filterValues.speaker = speaker;
    },

    QUERY_FILTER_ADD_SPEAKER(state: State, payload: QueryFilterMutationPayload): void {
        state.filterValues.speaker = [{ id: payload.value }];
    },

    FILTER_ADD_START_DATE(state: State, startDate: Date): void {
        state.startDate = startDate;
    },

    FILTER_ADD_END_DATE(state: State, endDate: Date): void {
        state.endDate = endDate;
    },

    RESET_STATE(state: State): void {
        state.filterValues.fullTextSearch = null;
        state.filterValues.category = [];
        state.filterValues['e-learning'] = false;
        state.filterValues['presence-seminar'] = false;
        state.filterValues['blended-learning'] = false;
        state.filterValues['BR'] = false;
        state.filterValues['JAV'] = false;
        state.filterValues['SBV'] = false;
        state.filterValues.committeeType = [];
        state.filterValues.speaker = [];
        state.filterValues.starterSeminars = [];
        state.startDate = today.toISOString();
        state.endDate = todayInTwoYears.toISOString();
        state.activeFilter = [];
        state.activeQueries = [];
        state.filterValues.locationNames = [];
        state.filterValues.regions = [];
    },

    ADD_ACTIVE_FILTER(state: State, filterName: string): void {
        state.activeFilter.push(filterName);
    },

    REMOVE_ACTIVE_FILTER(state: State, filterName: string): void {
        pull(state.activeFilter, filterName);
    },

    ADD_ACTIVE_QUERIES(state: State, payload: QueryFilterMutationPayload): void {
        state.activeQueries.push(payload);
    },

    SHOW_HIDDEN_FILTER(state: State, hideFilter: boolean): void {
        state.showHiddenFilter = hideFilter;
    },

    CHANGE_CURRENT_PAGE(state: State, page: number) {
        state.currentPage = page;
    }
};

export const actions = {
    applyFilter,
    addCategoryFilter,
    addRegionFilter,
    addTextFilter,
    addStarterSeminarFilter,
    addLocationFilter,
    addCourseFormatFilter,
    addCommitteeTypeFilter,
    addSpeakerFilter,
    filterAddStartDate,
    filterAddEndDate,
    resetFilter,
    applyReset,
    applyOffset,
    toggleHiddenFilter,
    changeCurrentPage,
    filterAddDateRange
};

export const getters = {
    fromDate: (state: State): DateRange => {
        const endDatePlusOne = dayjs(state.endDate)
            .add(23, 'hour')
            .toISOString();

        const startDate = formatDateForPayload(state.startDate);
        const endDate = formatDateForPayload(endDatePlusOne);

        return { from: startDate, to: endDate };
    },

    toDate: (state: State): DateRange => {
        const endDatePlusOne = dayjs(state.endDate)
            .add(23, 'hour')
            .toISOString();

        const startDate = formatDateForPayload(state.startDate);
        const endDate = formatDateForPayload(endDatePlusOne);

        return { from: startDate, to: endDate };
    },

    activeFilter(state: State): string[] {
        return uniq(state.activeFilter);
    },

    hasActiveFilter(state: State): boolean {
        return !isEmpty(state.activeFilter);
    },

    filterValues(state: State, getters: { toDate: DateRange; fromDate: DateRange }): GetterFilterValues {
        return {
            speakers: state.filterValues.speaker.map((item: { id }) => item.id),
            city: state.filterValues.locationNames.map(item => item.value),
            category: state.filterValues.category.map(item => item.name),
            starterSeminars: state.filterValues.starterSeminars,
            courseFormat: [
                state.filterValues['e-learning'] && 'e-learning',
                state.filterValues['presence-seminar'] && 'presence-seminar',
                state.filterValues['blended-learning'] && 'blended-learning'
            ].filter(item => !!item),
            committeeType: [
                state.filterValues['JAV'] && 'JAV',
                state.filterValues['SBV'] && 'SBV',
                state.filterValues['BR'] && 'BR'
            ].filter(item => !!item),
            fullTextSearch: state.filterValues.fullTextSearch,
            fromDate: getters.fromDate,
            toDate: getters.toDate,
            locationRegionId: state.filterValues.regions.map(region => region.id)
        };
    },

    filterMeta(state: State, getters): { offset: number; pageSize: number } {
        return {
            offset: state.offset,
            pageSize: state.pageSize
        };
    },

    currentPage(state: State) {
        return state.currentPage;
    },

    currentOffset(state: State, getters, rootState, rootGetters) {
        const currentOffset = (state.currentPage - 1) * rootGetters['seminarSearchFilterValues/filterMeta'].pageSize;
        return currentOffset <= 0 ? 0 : currentOffset;
    }
};
