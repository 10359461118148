var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g(_vm._b({staticClass:"cell",class:[
        _vm.widthClasses,
        _vm.shrinkClasses,
        _vm.autoClasses,
        _vm.offsetClasses,
        _vm.cellBlockyClasses,
        _vm.cellBlockContainerClasses,
        _vm.orderClasses
    ],style:({ 'align-self': _vm.alignSelf })},'div',_vm.$attrs,false),_vm.$listeners),[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }