export default {
    Gallery: () => import(/* webpackChunkName: "Gallery" */ '@/components/organisms/gallery/Gallery.vue'),

    SeminarSearch: () =>
        import(/* webpackChunkName: "SeminarSearch" */ '@/components/organisms/seminar-search/SeminarSearch.vue'),

    SeminarDetails: () =>
        import(/* webpackChunkName: "SeminarDetails" */ '@/components/organisms/seminar-details/SeminarDetails.vue'),

    OrderForm: () => import(/* webpackChunkName: "OrderForm" */ '@/components/organisms/order-form/OrderForm.vue'),

    Checkout: () => import(/* webpackChunkName: "Checkout" */ '@/components/organisms/checkout/Checkout.vue'),

    SpeakerDetailPage: () =>
        import(
            /* webpackChunkName: "SpeakerDetailPage" */ '@/components/organisms/speaker/SpeakerDetailPage/SpeakerDetailPage.vue'
        ),

    SpeakerSearch: () =>
        import(
            /* webpackChunkName: "SpeakerSearch" */ '@/components/organisms/speaker/SpeakerSearch/SpeakerSearch.vue'
        ),

    ContentContactForm: () =>
        import(/* webpackChunkName: "ContentContactForm" */ '@/components/molecules/content-contact-form/index.vue'),

    SiteHeader: () => import(/* webpackChunkName: "SiteHeader" */ '@/components/organisms/SiteHeader/index.vue'),

    LocationMap: () => import(/* webpackChunkName: "LocationMap" */ '@/components/molecules/location-map/index.vue')
};
