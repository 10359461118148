import { CommitteeTypeMutation } from '@/store/modules/seminarSearchFilterValues/types';

/**
 * Add committee type filter
 *
 * @param state
 * @param commit
 * @param payload
 */
export default async ({ state, commit }, payload: CommitteeTypeMutation): Promise<void> => {
    if (!payload.value) {
        commit('REMOVE_ACTIVE_FILTER', payload.key);
    } else {
        commit('ADD_ACTIVE_FILTER', payload.key);
    }

    commit('FILTER_ADD_COMMITTEE_TYPE', payload);
};
