<template>
    <v-row>
        <v-col cols="12" :sm="inline ? '6' : '12'">
            <BaseDatePicker
                v-model="value[0]"
                :label="i18n.BASE_DATE_RANGE_PICKER_FROM"
                :max="fromMaxDate"
                :min="fromMinDate"
                :required="required"
                :hide-icon="hideIcon"
                @open="$emit('openFrom')"
                @close="$emit('closeFrom')"
                :attach="attach"
            />
        </v-col>

        <v-spacer v-if="inline" />

        <v-col cols="12" :sm="inline ? '6' : '12'">
            <BaseDatePicker
                v-model="value[1]"
                :label="i18n.BASE_DATE_RANGE_PICKER_TO"
                :min="toMinDate"
                :max="toMaxDate"
                :hide-icon="hideIcon"
                @open="$emit('openTo')"
                @close="$emit('closeTo')"
                :attach="attach"
            />
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import validationRules from '@/mixins/validationRules';
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue';

export default {
    name: 'BaseDateRangePicker',

    mixins: [validationRules],

    components: {
        BaseDatePicker
    },

    computed: {
        ...mapGetters(['i18n'])
    },

    data() {
        return {
            fromMinDate: null,
            fromMaxDate: null,
            toMinDate: null,
            toMaxDate: null,
            fromDate: null,
            toDate: null
        };
    },

    props: {
        value: {
            type: Array,
            required: true,
            default: () => []
        },

        minRange: {
            type: Date,
            required: false,
            default: null
        },

        maxRange: {
            type: Date,
            required: false,
            default: null
        },

        required: {
            type: Boolean,
            default: false,
            required: false
        },

        hideIcon: {
            type: Boolean,
            default: false,
            required: false
        },

        attach: {
            type: String,
            required: false,
            default: '#app'
        },

        inline: {
            type: Boolean,
            default: true,
            required: false
        }
    },

    watch: {
        value(value) {
            const [fromDate, toDate] = value;
            this.fromDate = fromDate;
            this.toDate = toDate;
        },

        fromDate(value) {
            if (this.carbon(value) > this.carbon(this.toDate)) {
                this.$emit('input', [value, value]);
            }
            if (this.carbon(value) !== this.carbon(this.toDate)) {
                this.toMinDate = new Date(this.carbon(value) + 86400000);
            }
        },

        toDate(value) {
            if (this.carbon(value) < this.carbon(this.fromDate)) {
                this.$emit('input', [value, value]);
            }
            if (this.carbon(value) !== this.carbon(this.fromDate)) {
                this.fromMaxDate = new Date(this.carbon(value) - 86400000);
            }
        }
    },

    methods: {
        carbon(date) {
            return date.getTime();
        }
    },

    mounted() {
        const [fromDate, toDate] = this.value;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.fromMaxDate = this.maxRange;
        this.toMinDate = this.minRange;
        this.fromMinDate = this.minRange;
        this.toMaxDate = this.maxRange;
    }
};
</script>
