<template>
    <span class="mb-0">{{ compiledPrice }}</span>
</template>

<script>
import { priceFormatter } from '@/helper/currency';
import { mapGetters } from 'vuex';

export default {
    name: 'BaseSeminarPrice',

    props: {
        price: {
            type: Number,
            required: true
        }
    },

    computed: {
        ...mapGetters(['i18n']),

        compiledPrice() {
            if (this.price === 0.01) return this.i18n.BASE_PRICE_TBA;
            if (this.price === 0.02) return this.i18n.BASE_PRICE_FREE;

            return priceFormatter(this.price);
        }
    }
};
</script>
