




















export default {
    name: 'GridCell',

    props: {
        auto: {
            type: undefined
        },
        shrink: {
            type: undefined
        },
        width: {
            type: undefined
        },
        offset: {
            type: Object
        },
        cellBlocky: {
            type: Array
        },
        cellBlockContainer: {
            type: Array
        },
        alignSelf: {
            type: String
        },

        order: {
            type: undefined
        }
    },
    computed: {
        autoClasses() {
            if (typeof this.auto === 'string') {
                return 'auto';
            }
            if (typeof this.auto === 'object') {
                const classArray = [];
                for (const value of this.auto) {
                    classArray.push(`${value}-auto`);
                }
                return classArray.join(' ');
            }
            return null;
        },
        shrinkClasses() {
            if (typeof this.shrink === 'string') {
                return 'shrink';
            }

            if (typeof this.shrink === 'boolean') {
                return this.shrink ? 'shrink' : '';
            }

            if (typeof this.shrink === 'object') {
                const classArray = [];
                for (const value of this.shrink) {
                    classArray.push(`${value}-shrink`);
                }
                return classArray.join(' ');
            }

            return null;
        },
        widthClasses() {
            if (typeof this.width === 'string' || typeof this.width === 'number') {
                return `small-${this.width}`;
            }
            if (typeof this.width === 'object') {
                const classArray = [];
                for (const [key, value] of Object.entries(this.width)) {
                    classArray.push(`${key}-${value}`);
                }
                return classArray.join(' ');
            }
            return null;
        },
        offsetClasses() {
            if (this.offset) {
                const classArray = [];
                for (const [key, value] of Object.entries(this.offset)) {
                    classArray.push(`${key}-offset-${value}`);
                }
                return classArray.join(' ');
            }
            return null;
        },
        cellBlockyClasses() {
            if (this.cellBlocky) {
                const classArray = [];
                for (const value of this.cellBlocky) {
                    classArray.push(`${value}-cell-block-y`);
                }
                return classArray.join(' ');
            }

            return null;
        },
        cellBlockContainerClasses() {
            if (this.cellBlockContainer) {
                const classArray = [];
                for (const value of this.cellBlockContainer) {
                    classArray.push(`${value}-cell-block-container`);
                }
                return classArray.join(' ');
            }

            return null;
        },
        orderClasses() {
            if (typeof this.order === 'string' || typeof this.order === 'number') {
                return `small-order-${this.order}`;
            }
            if (typeof this.order === 'object') {
                const classArray = [];
                for (const [key, value] of Object.entries(this.order)) {
                    classArray.push(`${key}-order-${value}`);
                }
                return classArray.join(' ');
            }
            return null;
        }
    }
};
