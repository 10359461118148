import axios from 'axios';
import { ROUTE } from '../helper/routes';

const apiClient = axios.create({
    baseURL: `${ROUTE.API}`,
    withCredentials: false, // This is the default
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
    }
});

export default {
    getSpeaker(speakerId: Number) {
        return apiClient.get(`${ROUTE.SPEAKERS.SPEAKER}/${speakerId}`);
    },

    getSeminars(speakerId: Number) {
        return apiClient.get(`${ROUTE.SPEAKERS.SPEAKER}/${speakerId}${ROUTE.SPEAKERS.SEMINAR}`);
    },

    searchSpeaker(speakerName: String) {
        return apiClient.get(`${ROUTE.SPEAKERS.SPEAKER}${ROUTE.SPEAKERS.SEARCH}/${speakerName}`);
    }
};
