export const de_DE = {
    CHECKOUT_PRICETABLE_TITLE: 'Deine Buchungsanfrage',
    CHECKOUT_PRICETABLE_SEMINAR_COST: 'Seminarkosten',
    CHECKOUT_PRICETABLE_SEMINAR_COST_SUM: 'Summe Seminarkosten',
    CHECKOUT_PRICETABLE_SEMINAR_ANONYMOUS: 'Bitte Teilnehmerdaten ausfüllen',
    CHECKOUT_SUCCESS_TITLE: 'Super! <br /> Deine Anfrage ist bei uns eingetroffen.',
    CHECKOUT_SUCCESS_SUB_TITLE:
        'Im nächsten Schritt werden wir dir deine Buchung formal bestätigen – das dauert nicht lange. Wir freuen uns schon jetzt, dass du bei der BWS an Bord bist.',
    CHECKOUT_TITLE: 'Bitte ergänze Deine Daten',
    CHECKOUT_HEADLINE: 'Warenkorb',
    CHECKOUT_BILLING_CHECKBOX_LABEL: 'Abweichende Rechnungsanschrift angeben',
    CHECKOUT_SEMINARS_TITLE: 'Seminare',
    CHECKOUT_EMPTY: 'Dein Warenkorb ist leer',
    CHECKOUT_ERROR: 'Error',
    CHECKOUT_CHECKBOX_INTRO_1: 'Ich stimme der',
    CHECKOUT_CHECKBOX_INTRO_2: 'Ich stimme den',
    CHECKOUT_CHECKBOX_OUTRO: 'zu',
    CHECKOUT_CHECKBOX_TERMS: 'AGB',
    CHECKOUT_CHECKBOX_DPA: 'Datenschutzbestimmungen',
    CHECKOUT_CHECKBOX_DOA: 'Abtretungserklärung',
    CHECKOUT_REQUIRED_FIELDS: 'Du hast noch nicht alle Pflichtfelder ausgefüllt.',
    CHECKOUT_ORDER_BUTTON_INIT: 'Verbindlich buchen',
    CHECKOUT_ORDER_BUTTON_SENT: 'Erfolgreich gebucht',
    CHECKOUT_PRICETABLE_ADD_COST_NO_COSTS: 'Keine Zusatzkosten',
    CHECKOUT_PRICETABLE_ADD_COST_OVERNIGHT: 'Übernachtung',
    CHECKOUT_PRICETABLE_ADD_COST_CATERING: 'Verpflegung',
    CHECKOUT_PRICETABLE_ADD_COST_OVERNIGHT_BEFORE: 'Vorübernachtung',
    CHECKOUT_PRICETABLE_ADD_COST_OVERNIGHT_AFTER: 'Nachübernachtung',
    CHECKOUT_PRICETABLE_ADD_COST_NO_PRICE: 'Preis folgt',
    CHECKOUT_PRICETABLE_ADD_COST_SUM: 'Summe Übernachtung und Verpflegung',
    CHECKOUT_PRICETABLE_ADD_COST_FOOT1: 'zzgl. gesetzlicher Mehrwertsteuer',
    CHECKOUT_PRICETABLE_TAX: 'zzgl. gesetzlicher Mehrwertsteuer',
    CHECKOUT_PRICETABLE_ADD_COST_FOOT2:
        'Mehrkosten bei Auswahl Vor- oder Nachübernachtung können ggfs. erst nach der Buchung erfolgen',
    CHECKOUT_PRICETABLE_ADD_COST_WISHES: 'Sonderwünsche: ',
    CHECKOUT_DELETE_SEMINAR_TITLE: 'Seminar löschen',
    CHECKOUT_DELETE_SEMINAR_BUTTON_TITLE: 'Seminar löschen',
    CHECKOUT_DELETE_SEMINAR_BODY: 'Bist du dir sicher, dass du das Seminar löschen möchtest?',
    CHECKOUT_DELETE_SEMINAR_CONFIRM: 'Löschen',
    CHECKOUT_DELETE_SEMINAR_ABORT: 'Abbrechen',
    CHECKOUT_SEMINAR_LABEL_PARTICIPANT: 'Teilnehmer',
    CHECKOUT_SEMINAR_LABEL_DATE: 'Datum',
    CHECKOUT_SEMINAR_LABEL_LOCATION: 'Seminarort',
    CHECKOUT_SEMINAR_LABEL_ONLINE: 'Online',
    CHECKOUT_SEMINAR_LABEL_PRICE: 'Preis pro Teilnehmer',
    CHECKOUT_SEMINAR_LABEL_SEMNR: 'Seminarnummer',
    CHECKOUT_SEMINAR_LABEL_COMPLETE: 'Teilnehmerdaten vollständig',
    CHECKOUT_SEMINAR_LABEL_INCOMPLETE: 'Teilnehmer ausfüllen',
    CHECKOUT_SEMINAR_LABEL_PART_AMOUNT: 'Bitte mindestens einen Teilnehmer angeben',
    CHECKOUT_VALIDATION_UNSAVED_CONTACT: 'Bitte die Ansprechpartnerdaten speichern',
    CHECKOUT_VALIDATION_UNSAVED_BILLING: 'Bitte die Rechnungsdaten speichern',
    CHECKOUT_VALIDATION_UNSAVED_PARTICIPANTS: 'Bitte die Teilnehmerdaten speichern',
    ENTRY_SEARCH_LABEL: 'Ich interessiere mich für:',
    ENTRY_SEARCH_SELECT_LABEL: 'Alle Themenfelder',
    ENTRY_SEARCH_BUTTON_LABEL: 'Zu den Seminaren',
    SEARCH_SEMINAR_CARD_RESTRICTION: 'Teilnahmebeschränkt',
    SEARCH_SEMINAR_CARD_NO_PRICE: 'Preis folgt',
    SEARCH_SEMINAR_HIGH_CAPACITY: 'Nur wenige Plätze!',
    SEARCH_SEMINAR_DETAILS: 'Details',
    SEARCH_SEMINAR_CARD_MODULE: 'Modul',
    SEARCH_SEMINAR_CARD_MODULES: 'Module',
    SEARCH_SEMINAR_CARD_DATE_FROM: 'ab',
    SEARCH_SEMINAR_CARD_LOCATION_ONLINE: 'Online',
    SEARCH_SEMINAR_CARD_NO_LOCATION: 'Veranstaltungsort folgt',
    SEARCH_SEMINAR_CARD_LOCATION_RESTRICTION: 'Dieses Seminar ist für deinen Bezirk nicht zugelassen',
    SEARCH_NO_RESULTS_TITLE: 'Aktuell haben wir keine Seminare zu der von dir ausgewählten Suche.',
    SEARCH_NO_RESULTS_BODY: 'Ändere gerne deine Suchanfrage oder setze die Filtereinstellungen zurück',
    SEARCH_ERROR_BODY: 'Es ist leider ein Fehler bei der Suche aufgetreten',
    SEARCH_FILTER_SEMINAR_NUMBER_1: 'Die Seminarnummer',
    SEARCH_FILTER_SEMINAR_NUMBER_2: 'existiert nicht',
    SEARCH_FILTER_SEMINAR_VALIDATION: 'Die eingegebene Seminar Nummer ist nicht korrekt',
    SEARCH_FILTER_RESTRICTION_CHECK: 'Postleitzahl des Arbeitgebers',
    SEARCH_RESULTS_COUNT: 'Termine gefunden',
    SEARCH_RESULTS_COUNT_1: 'Termine im',
    SEARCH_RESULTS_COUNT_2: 'gefunden',
    SEARCH_FILTER_TITLE_SEMINAR_NUMBER: 'Seminarnummer',
    SEARCH_FILTER_TITLE_DATE: 'Zeitraum (von - bis)',
    SEARCH_FILTER_TITLE_CATEGORIES: 'Themenfeld',
    SEARCH_FILTER_TITLE_LOCATIONS: 'Seminarort',
    SEARCH_FILTER_TITLE_STARTER_SEMINARS: 'Starterseminar',
    SEARCH_FILTER_TITLE_COURSE_FORMATS: 'Kursformat',
    SEARCH_FILTER_TITLE_COMMITTEE_TYPES: 'Gremienart',
    SEARCH_FILTER_TITLE_SPEAKERS: 'Referent(in)',
    SEARCH_FILTER_TITLE_RESTRICTION: 'Seminare in deinem Bezirk',
    SEARCH_FILTER_TITLE_TEXT_SEARCH: 'Seminare durchsuchen',
    SEARCH_FILTER_LABEL_TEXT_SEARCH: 'Suchbegriff',
    SEARCH_FILTER_ERROR_RESTRICTION_1: 'Kein Bezirk für',
    SEARCH_FILTER_ERROR_RESTRICTION_2: 'gefunden',
    SEARCH_FILTER_LABEL_CATEGORIES: 'Thema auswählen',
    SEARCH_FILTER_LABEL_LOCATIONS: 'Seminarort auswählen',
    SEARCH_FILTER_LABEL_SPEAKERS: 'Referent(in) auswählen',
    SEARCH_FILTER_LABEL_STARTER_SEMINARS: 'Starterseminar auswählen',
    SEARCH_FILTER_MOBILE_BTN_LABEL: 'Filter',
    SEARCH_FILTER_MOBILE_TITLE: 'Filter',
    SEARCH_FILTER_MOBILE_SHOW_RESULTS: 'Ergebnisse zeigen',
    SEARCH_FILTER_RESET_BTN_LABEL: 'Suche zurücksetzen',
    SEARCH_FILTER_RESET_BTN_LABEL_MOBILE: 'zurücksetzen',
    DETAIL_PAGE_404_TITLE: 'Oops.',
    DETAIL_PAGE_404_SUBTITLE: 'Hier scheint etwas schiefgelaufen zu sein.',
    DETAIL_PAGE_404:
        'Das Seminar ist nicht mehr verfügbar. Bitte kontaktiere uns per <a href="mailto:bws@igbce.de">Mail</a> oder telefonisch unter <b>+49 511 7631-336</b> dann helfen wir dir dabei, einen Alternativtermin zu finden.',
    ORDER_FORM_TOPIC_LABEL: 'Themenauswahl',
    ORDER_FORM_TOPIC_CUSTOM_SWITCH_LABEL: 'Eigenes Thema vorschlagen',
    ORDER_FORM_TOPIC_CUSTOM_TEXT_LABEL: 'Dein Themenvorschlag',
    ORDER_FORM_PARTICIPANT_COUNT_LABEL: 'Anzahl der Teilnehmer',
    ORDER_FORM_DATE_FROM_LABEL: 'Von',
    ORDER_FORM_DATE_LABEL: 'Zeitraum',
    ORDER_FORM_DATE_LABEL_FROM: 'Von',
    ORDER_FORM_DATE_LABEL_TO: 'Bis',
    ORDER_FORM_LOCATION_LABEL: 'Seminarort',
    ORDER_FORM_LOCATION_CUSTOM_SWITCH_LABEL: 'Eigenen Schulungsort vorschlagen',
    ORDER_FORM_LOCATION_CUSTOM_LABEL: 'Dein Wunschort',
    ORDER_FORM_COMPANY_NAME_LABEL: 'Firmenname',
    ORDER_FORM_COMPANY_STREET_LABEL: 'Straße',
    ORDER_FORM_COMPANY_HOUSE_NUMBER_LABEL: 'Hausnummer',
    ORDER_FORM_COMPANY_ZIP_LABEL: 'PLZ',
    ORDER_FORM_COMPANY_CITY_LABEL: 'Stadt',
    ORDER_FORM_CONTACT_PERSON_GENDER_LABEL: 'Anrede',
    ORDER_FORM_CONTACT_PERSON_TITLE_LABEL: 'Titel',
    ORDER_FORM_CONTACT_PERSON_FIRST_NAME_LABEL: 'Vorname',
    ORDER_FORM_CONTACT_PERSON_LAST_NAME_LABEL: 'Nachname',
    ORDER_FORM_CONTACT_PERSON_PHONE_NUMBER_LABEL: 'Telefonnummer',
    ORDER_FORM_CONTACT_PERSON_MAIL_LABEL: 'Firmen-E-Mail',
    ORDER_FORM_LOCATION_1: 'Bildungszentrum Bad Münder',
    ORDER_FORM_LOCATION_2: 'Bildungszentrum Haltern am See',
    ORDER_FORM_LOCATION_3: 'Bildungszentrum Kagel-Möllenhorst',
    ORDER_FORM_TITLE_CONTACT_PERSON: 'Ansprechpartner',
    ORDER_FORM_RESPONSE_SUCCESS_TITLE: 'Vielen Dank',
    ORDER_FORM_RESPONSE_SUCCESS: 'Deine Anfrage wurde erfolgreich abgeschickt',
    ORDER_FORM_RESPONSE_REQUIRED: 'Du hast noch nicht alle Pflichfelder ausgefüllt',
    ORDER_FORM_RESPONSE_ERROR_TITLE: 'Ups...',
    ORDER_FORM_RESPONSE_ERROR: 'Es gab ein Problem beim Senden der Daten',
    PARTICIPANT_FORM_MEMBERSHIP_TYPE_NO_MEMBER: 'kein Gewerkschaftsmitglied',
    PARTICIPANT_FORM_MEMBERSHIP_TYPE_OTHER_MEMBER: 'Mitglied anderer Gewerkschaft',
    PARTICIPANT_FORM_MEMBERSHIP_TYPE_IG_BCE: 'Mitglied der IG BCE',
    PARTICIPANT_FORM_OVERNIGHT_OPTION_WITH: 'Verpflegung mit Übernachtung',
    PARTICIPANT_FORM_OVERNIGHT_OPTION_WITHOUT: 'Verpflegung ohne Übernachtung',
    PARTICIPANT_FORM_OVERNIGHT_OPTION_PRE: 'mit Vorübernachtung',
    PARTICIPANT_FORM_OVERNIGHT_OPTION_POST: 'mit Nachübernachtung',
    PARTICIPANT_FORM_ACCOMMODATION_INFO_PRE: 'Unterkunft',
    PARTICIPANT_FORM_CATERING_INFO_PRE: 'Verpflegung',
    PARTICIPANT_FORM_CATERING_INFO_POST: 'zzgl. gesetzlicher Mehrwertsteuer',
    OVERNIGHT_ID_NO_OVERNIGHT: 'Verpflegung ohne Übernachtung',
    OVERNIGHT_ID_OVERNIGHT: 'Verpflegung mit Übernachtung',
    OVERNIGHT_ID_OVERNIGHT_PRE: 'Verpflegung mit Übernachtung + Vorübernachtung',
    OVERNIGHT_ID_OVERNIGHT_POST: 'Verpflegung mit Übernachtung + Nachübernachtung',
    OVERNIGHT_ID_OVERNIGHT_PRE_POST: 'Verpflegung mit Übernachtung + Vorübernachtung + Nachübernachtung',
    CHECKOUT_CHECKBOX_DATA_PROCESSING:
        'Ich willige ein, dass die IGBCE und die IGBCE BWS GmbH als verantwortliche Stelle, die in der Anmeldung erhobenen personenbezogenen Daten wie Name, Vorname, Adresse, E-Mail-Adresse, Telefonnummer, zusätzlich zum Zwecke der Information zu Tätigkeiten der Betriebsräte, Informationen zu Weiterbildungsveranstaltungen und zu einer schnelleren erneuten Anmeldung zu Angeboten der IGBCE oder IGBCE BWS GMBH verarbeiten dürfen. Diese Einwilligung ist freiwillig. Durch eine nicht erteilte Einwilligung entstehen mir keine Nachteile. Die Einwilligung kann jederzeit mit Wirkung für die Zukunft widerrufen werden.',
    CART_MODAL_BUTTON_LABEL: 'In den Warenkorb',
    CART_MODAL_TITLE: 'Teilnehmeranzahl',
    CART_MODAL_BODY_TEXT: 'Bitte wählen Sie die Anzahl der Teilnehmer aus',
    CART_MODAL_INPUT_LABEL: 'Anzahl Teilnehmer',
    CART_MODAL_ACTION_ADD: 'Hinzufügen',
    SEMINAR_DETAIL_ALTERNATIVES_TITLE: 'Weitere Termine für dieses Seminar',
    SEMINAR_DETAIL_ALTERNATIVES_MODULES_DATE_FROM: 'ab',
    SEMINAR_DETAIL_ALTERNATIVES_MODULE: 'Modul',
    SEMINAR_DETAIL_ALTERNATIVES_MODULES: 'Module',
    SEMINAR_DETAIL_FACILITY_SPECS_TITLE: 'Ausstattung Seminarort',
    SEMINAR_DETAIL_HEADER_BACK_LINK_1: 'Zurück',
    SEMINAR_DETAIL_HEADER_BACK_LINK_2: 'zur Suche',
    SEMINAR_DETAIL_INFO_PAST_SEMINAR: 'Seminar hat bereits stattgefunden',
    SEMINAR_DETAIL_INFO_SHOW_MORE: 'Mehr',
    SEMINAR_DETAIL_INFO_SHOW_LESS: 'Weniger',
    SEMINAR_DETAIL_INFO_SHOW_INFORMATION: 'Informationen anzeigen',
    SEMINAR_DETAIL_INFO_TABLE_HEAD_MODULES: 'Module',
    SEMINAR_DETAIL_INFO_TABLE_HEAD_MODULE: 'Modul',
    SEMINAR_DETAIL_INFO_TABLE_HEAD_DATE: 'Datum',
    SEMINAR_DETAIL_INFO_TABLE_HEAD_BEGIN: 'Beginn',
    SEMINAR_DETAIL_INFO_TABLE_HEAD_END: 'Ende',
    SEMINAR_DETAIL_LOCATION_ONLINE: 'Online',
    SEMINAR_DETAIL_NO_LOCATION: 'Veranstaltungsort folgt',
    SEMINAR_DETAIL_LOCATION_ADDRESS_TITLE: 'Adresse',
    SEMINAR_DETAIL_SPEAKER_TITLE: 'Dein(e) Referent(in)',
    SEMINAR_DETAIL_SPEAKER_NO_DESCRIPTION: 'Keine Beschreibung',
    SEMINAR_DETAIL_SPECS_DATE: 'Termin',
    SEMINAR_DETAIL_SPECS_MODULE_DATES: 'Modultermine',
    SEMINAR_DETAIL_SPECS_DATE_BEGINNING: 'Beginn',
    SEMINAR_DETAIL_SPECS_DATE_END: 'Ende',
    SEMINAR_DETAIL_SPECS_DATE_CLOCK: 'Uhr',
    SEMINAR_DETAIL_SPECS_DURATION: 'Dauer',
    SEMINAR_DETAIL_SPECS_LOCATION: 'Seminarort',
    SEMINAR_DETAIL_SPECS_RESTRICTION: 'Teilnahmebeschränkungen',
    SEMINAR_DETAIL_SPECS_RESTRICTION_DISTRICT: 'Bezirk',
    SEMINAR_DETAIL_SPECS_RESTRICTION_COUNTY_DISTRICT: 'Landesbezirk',
    SEMINAR_DETAIL_SPECS_SPEAKER: 'Referent(in)',
    SEMINAR_DETAIL_SPECS_SPEAKER_DETAIL: 'Mehr lesen',
    SEMINAR_DETAIL_SPECS_EXEMPTION: 'Freistellung',
    SEMINAR_DETAIL_SPECS_PRICE: 'Preis',
    SEMINAR_DETAIL_SPECS_FEES: 'Seminargebühren',
    SEMINAR_DETAIL_SPECS_ACCOMMODATION: 'Unterkunft',
    SEMINAR_DETAIL_SPECS_CATERING: 'Verpflegung',
    SEMINAR_DETAIL_SPECS_TAX: 'zzgl. gesetzlicher MwSt.',
    SEMINAR_DETAIL_TOPICS_TITLE: 'Die Themen dieses Seminars',
    SEMINAR_DETAIL_CONTACT_PHONE: 'Tel',
    SEMINAR_DETAIL_CONTACT_EMAIL: 'Firmen-E-Mail',
    SEMINAR_DETAIL_CONTACT_CONTACT_HEADLINE: 'Deine Ansprechperson',
    SEMINAR_DETAIL_DISTRICT_CHECK_LABEL: 'Postleitzahl des Arbeitgebers',
    SEMINAR_DETAIL_DISTRICT_CHECK_ERROR: 'Die von dir angegebene Postleitzahl existiert nicht',
    SEMINAR_DETAIL_DISTRICT_CHECK_FAILURE_NOTICE:
        'Auch wenn du nicht aus dem Bezirk kommst, für den das Seminar vorgesehen ist, kannst du das Seminar unter Vorbehalt buchen. Wir melden uns bei dir, ob deine Teilnahme möglich ist.',
    SEMINAR_DETAIL_DISTRICT_CHECK_FAILURE: 'ist für dieses Seminar nicht teilnahmeberechtigt',
    SEMINAR_DETAIL_DISTRICT_CHECK_INTRO: 'Dein Bezirk',
    SEMINAR_DETAIL_DISTRICT_CHECK_SUCCESS: 'ist für dieses Seminar teilnahmeberechtigt',
    SEMINAR_DETAIL_DISTRICT_CHECK_TITLE: 'Dieses Seminar ist teilnahmebeschränkt, prüfe deine Berechtigung',
    SEMINAR_DETAIL_DOCUMENT_DOWNLOAD_TITLE: 'Dokument',
    SEMINAR_DETAIL_DOCUMENT_DOWNLOAD_LABEL: 'Dokumente herunterladen',
    SEMINAR_DETAIL_PRINT: 'Drucken',
    SEMINAR_DETAIL_RECOMMEND: 'Empfehlen',
    SEMINAR_DETAIL_RECOMMEND_SUBJECT: 'Folgendes Seminar könnte dich interessieren',
    SEMINAR_DETAIL_RECOMMEND_GREETING: 'Hallo, ',
    SEMINAR_DETAIL_RECOMMEND_RECOMMENDATION: 'ich habe gerade dieses interessante Seminar bei IGBCE BWS gesehen: ',
    SEMINAR_DETAIL_RECOMMEND_REGARDS: 'Viele Grüße',
    NEWSLETTER_FORM_REGISTER_LABEL: 'Jetzt abonnieren',
    NEWSLETTER_FORM_FAIL_TITLE: 'Something went wrong!',
    NEWSLETTER_FORM_FAIL_TAC: 'Bitte Bedingungen im Pimcore definieren.',
    NEWSLETTER_FORM_FAIL_TEXT:
        'Entweder funktioniert bei uns gerade etwas nicht oder die von dir eingegebene E-Mail konnte nicht auf Echtheit geprüft werden.',
    FORM_COMPANY_NAME: 'Firmenname',
    FORM_DEPARTMENT_NAME: 'Abteilung',
    FORM_GENDER: 'Anrede',
    FORM_SALUTATION: 'Titel',
    FORM_FIRST_NAME: 'Vorname',
    FORM_LAST_NAME: 'Nachname',
    FORM_STREET: 'Straße',
    FORM_HOUSE_NUMBER: 'Hausnummer',
    FORM_ZIP: 'PLZ',
    FORM_CITY: 'Stadt',
    FORM_COUNTRY: 'Land',
    FORM_MAIL: 'Firmen-E-Mail',
    FORM_PHONE: 'Telefon',
    FORM_MOBILE_PHONE: 'Mobilnummer',
    FORM_GENDER_FEMALE: 'Frau',
    FORM_GENDER_MALE: 'Herr',
    FORM_GENDER_NO_GENDER: 'Sonstiges',
    FORM_TITLE_DOCTOR: 'Dr.',
    FORM_TITLE_PROFESSOR: 'Prof.',
    FORM_TITLE_PROFESSOR_DOCTOR: 'Prof. Dr.',
    FORM_REQUIRED_FIELD: 'Pflichfeld',
    FORM_REQUIRED_FIELDS: 'Pflichtfelder',
    FORM_VALIDATION_EMAIL: 'Deine E-Mail-Adresse hat nicht das richtige Format',
    FORM_VALIDATION_TERMS: 'Stimme bitte den Bedingungen zu.',
    FORM_VALIDATION_ZIP: 'Bitte eine gültige Postleitzahl eingeben',
    FORM_VALIDATION_MEMBERSHIP_NUMBER: 'Bitte eine gültige Mitgliedsnummer eingeben',
    FORM_CONTACT_SAVE: 'Kontaktdaten speichern',
    FORM_CONTACT_TITLE: 'Ansprechpartner',
    FORM_BILLING_SAVE: 'Rechnungsanschrift speichern',
    FORM_BILLING_TITLE: 'Rechnungsanschrift',
    FORM_PARTICIPANT_MEMBERSHIP_TYPE: 'Mitgliedschaft',
    FORM_PARTICIPANT_MEMBERSHIP_NUMBER: 'Mitgliedsnummer',
    FORM_PARTICIPANT_OPTIONS_TITLE: 'Buchungsoptionen',
    FORM_PARTICIPANT_MOBILE_PHONE_INFO:
        'Aufgrund der aktuellen Lage benötigen wir deine mobile Telefonnummer, mit der wir dich bei Änderungen kurzfristig erreichen können.',
    FORM_PARTICIPANT_OPTIONS_WISHES: 'Sonderwünsche',
    FORM_PARTICIPANT_OPTIONS_CATERING: 'Verpflegung',
    FORM_PARTICIPANT_SAVE: 'Teilnehmer speichern',
    FORM_PARTICIPANT_ADDRESS_TITLE: 'Versandadresse für die Seminarunterlagen',
    SEMINAR_DIRECT_SEARCH_LABEL: 'BWS-000-000000-00',
    SEMINAR_DETAIL_LOCATION_LINK: 'Mehr zum Standort',
    SEMINAR_DETAIL_INFO_RESTRICTION: 'Teilnahmebeschränkt',
    SPEAKER_GENDERED_TITLE_FEMALE: 'Deine Referentin',
    SPEAKER_GENDERED_TITLE_MALE: 'Dein Referent',
    SPEAKER_GENDERED_TITLE_FEMALE_SEMINARS: 'diese Referentin',
    SPEAKER_GENDERED_TITLE_MALE_SEMINARS: 'diesen Referent',
    SPEAKER_ALL_SEMINARS: 'Weitere Termine für',
    SPEAKER_SHOW_MORE: 'Mehr anzeigen',
    SPEAKER_CATEGORIES_TITLE: 'Themenfelder',
    SPEAKER_EVENT_LOCATION_ONLINE: 'Online',
    SPEAKER_EVENT_NO_LOCATION: 'Veranstaltungsort folgt',
    SPEAKER_EVENT_MODULE_DATE_FROM: 'Ab',
    SPEAKER_EVENT_MODULE: 'Modul',
    SPEAKER_EVENT_MODULES: 'Module',
    SPEAKER_SEARCH_BUTTON: 'Suchen',
    SPEAKER_SEARCH_FORM_LABEL: 'Nach Vor- und/oder Nachnamen suchen',
    SPEAKER_SEARCH_TITLE: 'Deine Suchergebnisse:',
    SPEAKER_SEARCH_RESET: 'Zurücksetzen',
    SPEAKER_SEARCH_NO_RESULT: 'Zu deiner Suchanfrage hab es leider keine Ergebnisse',
    SPEAKER_SEARCH_HEADLINE: 'Referent*innen-Sucher',
    SEMINAR_DATE_BLENDED_LEARNING: 'Jederzeit online verfügbar',
    BASE_DATE_PICKER_BUTTON_LABEL_OK: 'Ok',
    BASE_DATE_RANGE_PICKER_TO: 'Bis',
    BASE_DATE_RANGE_PICKER_FROM: 'Von',

    COOKIE_BANNER_LINK_LABEL: 'hier',
    COOKIE_BANNER_BTN_SETTINGS: 'Einstellungen',
    COOKIE_BANNER_BTN_ACCEPT: 'Zustimmen',
    COOKIE_BANNER_SETTINGS_CHECKBOX_LABEL: 'Erlaube Performance Cookies',
    COOKIE_BANNER_SETTINGS_BTN_BACK: 'Zurück',
    COOKIE_BANNER_SETTINGS_BTN_SAVE_AND_CLOSE: 'Speichern und schließen',
    COOKIE_BANNER_SETTINGS_TITLE: 'Einstellungen',

    COOKIE_BANNER_CONTENT:
        'Wir verwenden Cookies. Um Dir einen uneingeschränkten Service zu gewährleisten, stimme der Cookie-Nutzung mit „Zustimmen“ zu. In den „Einstellungen“ kannst du die Cookie-Einstellungen so verändern, dass nur essenzielle Cookies gesetzt werden. Weitere Informationen zur Verwendung von Cookies findest du',
    BASE_PRICE_TBA: 'Preis folgt',
    BASE_PRICE_FREE: 'Kostenfrei',
    SEMINAR_FILTER_TEASER: {
        NO_RESULTS_BODY: 'Schaue gerne in der <a href="/seminare-suchen">Seminarsuche</a> nach ähnlichen Themen'
    },
    BASE: {
        SEMINAR: {
            LOCATION: {
                TBA: 'Veranstaltungsort folgt',
                WEB_SEMINAR: 'Online'
            },
            DATE: {
                TBA: 'Datum folgt'
            }
        }
    },

    FORM_VALIDATION: {
        BWS_MEMBERSHIP: 'Die Migliedsnummer muss aus 7 Ziffern bestehen',
        EMAIL: 'Bitte eine gülte E-Mail-Addresse angeben',
        MAX_FILE_SIZE: 'Die Datei darf nicht größer als {maxFileSize}MB sein',
        MFA: 'Der Code muss 6 Ziffern enthalten',
        'PASSWORD.ALL': 'Das Passwort entspricht nicht den Richtlinien',
        REQUIRED: 'Pflichtfeld',
        SEMINAR_NUMBER: 'Bitte eine gültige Seminarnummer angeben',
        URL: 'URL muss im korrekten Format sein.',
        ZIP: 'Bitte eine Gültige PLZ angeben',
        MAX_CHARS: 'Dieses Feld darf maximal {maxValue} Zeichen enthalten',
        PASSWORD_ALL: ''
    },

    CONTENT_ELEMENTS: {
        CONTACT_FORM: {
            LABELS: {
                FIRST_NAME: 'Vorname',
                LAST_NAME: 'Nachname',
                COMPANY_NAME: 'Name des Betriebs',
                MAIL: 'E-Mail-Adresse',
                PHONE: 'Telefonnummer',
                MESSAGE: 'Deine Nachricht',
                BUTTON: 'Senden',
                DATA_PROCESSING_PRE: 'Ich stimme den',
                DATA_PROCESSING_URL_LABEL: 'Datenschutzbestimmungen',
                DATA_PROCESSING_URL: 'https://www.igbce-bws.de/meta/datenschutz',
                DATA_PROCESSING_POST: 'zu',
                SUCCESS_MESSAGE: 'Deine Nachricht wurde erfolgreich versandt.'
            }
        }
    },

    MAIN_NAVIGATION: {
        QUICK_SEARCH: {
            TITLE: 'Schnellsuche',
            DIRECT_SEARCH: {
                LABEL: 'Seminar Nr. eingeben'
            },
            DATE_SEARCH: {
                LABEL: 'Nach Datum filtern',
                BTN: {
                    NO_RESULTS: 'Keine Seminare gefunden',
                    RESULTS: 'Seminare anzeigen',
                    SEARCH: 'Seminare suchen'
                }
            }
        }
    },

    SEMINAR_SEARCH: {
        FILTER: {
            REGION: {
                TITLE: 'Landesbezirk',
                LABEL: 'Landesbezirk wählen'
            }
        }
    },

    SEMINAR_CARD: {
        RESTRICTION: 'Teilnahmebeschränkung'
    },

    SEMINAR_FILTER: {
        EXPAND_BTN: {
            LABEL: {
                MORE: 'Weitere Filter einblenden',
                LESS: 'Filter ausblenden'
            }
        }
    }
};
