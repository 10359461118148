















export default {
    name: 'GridRow',

    props: {
        gridX: {
            type: Boolean,
            default: false
        },
        gridY: {
            type: Boolean,
            default: false
        },
        marginX: {
            type: Boolean,
            default: false
        },
        paddingX: {
            type: Boolean,
            default: false
        },
        collapse: {
            type: Object
        },
        block: {
            type: Object
        },
        frame: {
            type: Array
        }
    },
    computed: {
        collapseClasses() {
            if (this.collapse) {
                const classArray = [];
                for (const [key, value] of Object.entries(this.collapse)) {
                    classArray.push(`${key}-${value}-collapse`);
                }
                return classArray.join(' ');
            }
            return null;
        },
        blockClasses() {
            if (this.block) {
                const classArray = [];
                for (const [key, value] of Object.entries(this.block)) {
                    classArray.push(`${key}-up-${value}`);
                }
                return classArray.join(' ');
            }
            return null;
        },
        frameClasses() {
            if (this.frame) {
                const classArray = [];
                for (const value of this.frame) {
                    classArray.push(`${value}-grid-frame`);
                }
                return classArray.join(' ');
            }

            return null;
        }
    }
};
