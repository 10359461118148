/**
 * Add end date filter
 *
 * @param commit
 * @param date
 */
export default async ({ commit }, date: Date): Promise<void> => {
    commit('FILTER_ADD_END_DATE', date);
    commit('ADD_ACTIVE_FILTER', 'endDate');
};
