import { jumpToTop } from '@/helper/jumpToTop';

export default async ({ dispatch, commit }: any, page): Promise<void> => {
    try {
        commit('CHANGE_CURRENT_PAGE', page);
        jumpToTop();
    } finally {
        await dispatch('seminarSearchResults/fetchSeminarResults', null, { root: true });
    }
};
