<template>
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedDate"
                :label="fieldLabel"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
                :rules="validation"
            >
                <template v-slot:prepend-inner v-if="!hideIcon">
                    <v-icon dense class="mr-2">far fa-calendar-alt</v-icon>
                </template>
            </v-text-field>
        </template>

        <v-date-picker v-model="localDate" scrollable :max="maxDate" :min="minDate">
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="saveDate">
                {{ i18n.BASE_DATE_PICKER_BUTTON_LABEL_OK }}
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import dateHelper from '@/helper/dateHelper';
import validationRules from '@/mixins/validationRules';

export default {
    name: 'BaseDatePicker',

    mixins: [validationRules],

    data() {
        return {
            localDate: new Date().toISOString().substr(0, 10),
            menu: false,
            modal: false,
            maxDate: null,
            minDate: null
        };
    },

    props: {
        label: {
            type: String,
            require: true,
            default: ''
        },

        value: {
            type: Date,
            require: false,
            default: new Date()
        },

        max: {
            type: Date,
            require: false,
            default: null
        },

        min: {
            type: Date,
            require: false,
            default: null
        },

        required: {
            type: Boolean,
            default: false,
            required: false
        },

        rules: {
            type: Array,
            required: false
        },

        hideIcon: {
            type: Boolean,
            default: false,
            required: false
        },

        attach: {
            type: String,
            required: false,
            default: '#app'
        }
    },

    computed: {
        ...mapGetters(['i18n']),

        formattedDate() {
            return dateHelper(this.value, 'dateLong');
        },

        validation() {
            if (this.rules) return this.rules;
            if (this.required) return this.requiredRules;

            return undefined;
        },

        fieldLabel() {
            if (this.required) return `${this.label} *`;
            return this.label;
        }
    },

    watch: {
        value(value) {
            this.localDate = value.toISOString().substr(0, 10);
        },

        min(value) {
            if (!value) {
                this.minDate = value;
            } else {
                this.minDate = value.toISOString().substr(0, 10);
            }
        },

        max(value) {
            if (!value) {
                this.maxDate = value;
            } else {
                this.maxDate = value.toISOString().substr(0, 10);
            }
        },

        modal(value) {
            if (value) {
                this.$emit('open');
            } else {
                this.$emit('close');
            }
        }
    },

    methods: {
        saveDate() {
            this.$emit('input', new Date(this.localDate));
            this.modal = false;
        }
    },

    mounted() {
        this.localDate = this.value.toISOString().substr(0, 10);
    }
};
</script>
