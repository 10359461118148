<template>
    <v-btn large :color="color" depressed :dark="dark" v-on="$listeners" v-bind="$attrs">
        <span :class="!dark ? 'text--button-white' : 'text--button'">
            <slot />
        </span>
    </v-btn>
</template>

<script>
export default {
    name: 'BaseButton',

    props: {
        dark: {
            type: Boolean,
            required: false,
            default: false
        },

        color: {
            type: String,
            required: false,
            default: 'primary'
        }
    }
};
</script>
