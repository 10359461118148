export default (secondary: boolean, inverted: boolean): string => {
  if (!secondary && inverted) {
    return "gk_btn--primary-inverted";
  }

  if (secondary && !inverted) {
    return "gk_btn--secondary";
  }

  if (secondary && inverted) {
    return "gk_btn--secondary-inverted";
  }

  return "gk_btn--primary";
};
