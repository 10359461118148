import { isEmpty } from 'lodash';

/**
 * Add category filter
 *
 * @param state
 * @param commit
 * @param categoryNames
 */
export default async ({ state, commit }, categoryNames: string[] = []): Promise<void> => {
    commit('FILTER_ADD_CATEGORY', categoryNames);

    if (isEmpty(categoryNames)) {
        commit('REMOVE_ACTIVE_FILTER', 'category');
    } else {
        commit('ADD_ACTIVE_FILTER', 'category');
    }
};
