<template>
    <span v-bind="$attrs" v-on="$listeners">
        <slot />
    </span>
</template>

<script>
export default {
    name: 'Span'
};
</script>
