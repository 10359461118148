import { Status } from '@/helper/status';
import { isEmpty } from 'lodash';
import typeCastHelper from '@/helper/typeCastHelper';

type SelectedQuery = {
    name: string;
    value: boolean | string | number;
};

type QueryFilterEntry = {
    key: string;
    mutation: string;
};

/**
 * Retrieve seminar results
 *
 * @param dispatch
 * @param commit
 * @param rootGetters
 * @param query
 */
export default async ({ dispatch, commit, rootGetters }: any, query: Object): Promise<void> => {
    const addQueryFilter = (queue: QueryFilterEntry[]): void => {
        if (isEmpty(queue)) return;
        if (isEmpty(query)) return;

        const queries = Object.keys(query).map(item => ({
            name: item,
            value: typeCastHelper(query[item])
        }));

        for (const item of queue) {
            const { key, mutation } = item;
            const selectedQuery: SelectedQuery[] = queries.filter(item => item.name === key);

            if (isEmpty(selectedQuery)) continue;

            const queryValue = selectedQuery[0].value;

            try {
                commit(mutation, { key, value: queryValue }, { root: true });
                commit('seminarSearchFilterValues/ADD_ACTIVE_QUERIES', { key, value: queryValue }, { root: true });

                if (!rootGetters['seminarSearchFilterValues/activeFilter'].includes(key)) {
                    commit('seminarSearchFilterValues/ADD_ACTIVE_FILTER', key, { root: true });
                }
            } catch (err) {
                // eslint-disable-next-line no-console
                console.error(err);
            }
        }
    };

    await commit('CHANGE_STATUS', Status.Loading);

    try {
        if (!isEmpty(query)) {
            dispatch('seminarSearchFilterValues/applyReset', null, { root: true });

            const queue: QueryFilterEntry[] = [
                { key: 'locations', mutation: 'seminarSearchFilterValues/QUERY_FILTER_ADD_LOCATION' },
                { key: 'categories', mutation: 'seminarSearchFilterValues/QUERY_FILTER_ADD_CATEGORY' },
                { key: 'speaker', mutation: 'seminarSearchFilterValues/QUERY_FILTER_ADD_SPEAKER' },
                { key: 'endDate', mutation: 'seminarSearchFilterValues/QUERY_FILTER_ADD_END_DATE' },
                { key: 'startDate', mutation: 'seminarSearchFilterValues/QUERY_FILTER_ADD_START_DATE' },
                { key: 'SBV', mutation: 'seminarSearchFilterValues/FILTER_ADD_COMMITTEE_TYPE' },
                { key: 'JAV', mutation: 'seminarSearchFilterValues/FILTER_ADD_COMMITTEE_TYPE' },
                { key: 'BR', mutation: 'seminarSearchFilterValues/FILTER_ADD_COMMITTEE_TYPE' },
                { key: 'e-learning', mutation: 'seminarSearchFilterValues/FILTER_ADD_COURSE_FORMAT' },
                { key: 'blended-learning', mutation: 'seminarSearchFilterValues/FILTER_ADD_COURSE_FORMAT' },
                { key: 'presence-seminar', mutation: 'seminarSearchFilterValues/FILTER_ADD_COURSE_FORMAT' }
            ];

            addQueryFilter(queue);
        }
    } finally {
        await dispatch('fetchSeminars');
        dispatch('loadCheckout', null, { root: true });
        commit('CHANGE_STATUS', Status.Ready);
    }
};
