import { jumpToTop } from '@/helper/jumpToTop';

/**
 * Apply offset
 *
 * @param dispatch
 * @param commit
 * @param offset
 */
export default async ({ dispatch, commit }: any, offset: Number): Promise<void> => {
    try {
        commit('CHANGE_CURRENT_PAGE', 0);
        jumpToTop();
    } finally {
        await dispatch('seminarSearchResults/fetchSeminarResults', null, { root: true });
    }
};
