import { isEmpty } from 'lodash';

/**
 * Add speaker filter
 *
 * @param commit
 * @param speaker
 */
export default async ({ commit }, speaker: string[] = []) => {
    commit('FILTER_ADD_SPEAKER', speaker);

    if (isEmpty(speaker)) {
        commit('REMOVE_ACTIVE_FILTER', 'speaker');
    } else {
        commit('ADD_ACTIVE_FILTER', 'speaker');
    }
};
