export const getCourseFormatIcon = (courseFormat: String) => {
    switch (courseFormat) {
        case 'e-learning':
            return 'fa-solid fa-display';
        case 'presence-seminar':
            return 'fa-solid fa-user-group';
        default:
            return 'fa-solid fa-shapes';
    }
};
