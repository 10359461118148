import SeminarService from '@/services/SeminarService';

export default async ({ dispatch, rootGetters }: any): Promise<void> => {
    dispatch('fetchFilterEntries', {
        mutation: 'ADD_CATEGORIES',
        callback: () => SeminarService.getCategories()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_COURSE_FORMATS',
        callback: () => SeminarService.getCourseFormats()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_COMMITTEE_TYPES',
        callback: () => SeminarService.getCommitteeTypes()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_SPEAKERS',
        callback: () => SeminarService.getSpeakers()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_LOCATIONS',
        callback: () => SeminarService.getLocations()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_REGIONS',
        callback: () => SeminarService.getRegions()
    });

    dispatch('fetchFilterEntries', {
        mutation: 'ADD_STARTER_SEMINAR_VALUES',
        callback: () => SeminarService.getStarterSeminars()
    });
};
