import { max } from 'lodash';

/**
 *
 * Find elements with specified class name and set height to largest value
 *
 * @param className
 */
export default (className: string = 'js--eqHeight') => {
    const setElementsHeight = (windowWidth: number = 0) => {
        const elements = document.getElementsByClassName(className);
        const heights: number[] = [];
        for (const element of elements) {
            if (element instanceof HTMLElement) {
                heights.push(element.clientHeight);
            }
        }

        const maxHeight = max(heights);

        if (windowWidth > 1024) {
            for (const element of elements) {
                if (element instanceof HTMLElement) {
                    element.style.height = `${maxHeight}px`;
                }
            }
        } else {
            for (const element of elements) {
                if (element instanceof HTMLElement) {
                    element.style.height = `auto`;
                }
            }
        }
    };

    setElementsHeight(1025);

    window.addEventListener('resize', () => {
        setElementsHeight(window.screen.width);
    });
};
