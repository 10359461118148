<template>
    <span>{{ name }}</span>
</template>

<script>
import seminarTypeNames from '@/data/seminarTypeNames';

export default {
    name: 'BaseSeminarTypeName',

    props: {
        seminarType: {
            type: String,
            required: true
        }
    },

    computed: {
        name() {
            return seminarTypeNames[this.seminarType];
        }
    }
};
</script>
