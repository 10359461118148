<template>
    <div class="inline-flex align-center rounded-[3px] text-gk-sm px-[8px] py-[3px]" :class="badgeBgColor">
        <div class="flex-initial pr-text-sm">
            <i :class="[iconName, iconColor]"></i>
        </div>

        <div class="flex-initial">
            <span :class="labelColor">{{ label }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseBadge',

    props: {
        badgeColor: {
            type: String,
            default: 'bg-gk-darkgray'
        },

        iconName: {
            type: String,
            default: 'fa-solid fa-triangle-exclamation'
        },

        hasColor: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            require: true
        }
    },

    computed: {
        iconColor() {
            return this.hasColor ? 'text-gk-white' : '';
        },

        labelColor() {
            return this.hasColor ? 'text-gk-white' : '';
        },

        badgeBgColor() {
            return this.hasColor ? this.badgeColor : '';
        }
    }
};
</script>
