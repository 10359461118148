import { Status } from '@/helper/status';
import fetchSeminarResults from './actions/fetchSeminarResults';
import fetchSeminars from './actions/fetchSeminars';
import { State } from './types';

export const namespaced = true;

export const state: State = {
    seminars: [],
    seminarCount: 0,
    eventsCount: 0,
    status: Status.Init
};

export const mutations = {
    CHANGE_STATUS(state: State, status: number): void {
        state.status = status;
    },

    ADD_SEMINARS(state: State, seminars: object[]): void {
        state.seminars = seminars;
    },

    ADD_SEMINAR_COUNT(state: State, seminarCount: number): void {
        state.seminarCount = seminarCount;
    },

    ADD_EVENTS_COUNT(state: State, eventsCount: number): void {
        state.eventsCount = eventsCount;
    }
};

export const actions = {
    fetchSeminarResults,
    fetchSeminars
};

export const getters = {
    getTotal: (state: State): number => {
        return state.seminarCount;
    },

    getTotalEvents: (state: State): number => {
        return state.eventsCount;
    },

    getPageCount: (state: State, getters, rootState, rootGetters): number => {
        const pageSize = rootGetters['seminarSearchFilterValues/filterMeta'].pageSize;

        return Math.ceil(state.seminarCount / pageSize);
    }
};
