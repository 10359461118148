export const ROUTE = {
    API: '/api/v1',

    SEMINAR: {
        SEARCH: '/seminar-search',
        CATEGORIES: '/seminar-category',
        FORMATS: '/seminar-course-formats',
        COMMITTEE: '/seminar-committee-types',
        SPEAKERS: '/speakers',
        LOCATIONS: '/locations-names',
        DISTRICTS: '/districts',
        DISTRICT: '/districts/zip',
        DETAIL: '/seminar',
        SEMINAR_ROUTE_LOOKUP: '/seminar-url',
        REGIONS: '/location-regions',
        STARTER_SEMINARS: '/starter-seminars'
    },
    CART: {
        ADD: '/cart/add',
        DELETE: '/cart',
        LIST: '/cart',
        UPDATE: '/cart'
    },
    CHECKOUT: {
        ADD: '/checkout/add'
    },
    MAILCHIMP: {
        REGISTER: '/polargold_pimcore_mailchimp/api/add_to_list'
    },
    ORDER_FORM: {
        ADD: 'order-form/add'
    },
    SPEAKERS: {
        SPEAKER: '/speakers',
        SEMINAR: '/seminars',
        SEARCH: '/search'
    }
};

export const ROUTE_V2 = {
    API: '/api/v2',

    FILTER: {
        DISTRICTS: {
            INDEX: '/districts'
        },

        COUNTY_DISTRICTS: {
            INDEX: '/county-districts'
        }
    }
};
