import { random } from 'lodash';

/**
 * Reset filter
 *
 * @param commit
 */
export default async ({ commit }): Promise<void> => {
    commit('RESET_STATE');
};
