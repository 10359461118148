import axios, { AxiosInstance } from 'axios';
import { ROUTE, ROUTE_V2 } from '@/helper/routes';
import { Region } from '@/types';

const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
};

const apiClient: AxiosInstance = axios.create({
    baseURL: `${ROUTE.API}`,
    withCredentials: false, // This is the default
    headers
});

const apiClientV2: AxiosInstance = axios.create({
    baseURL: `${ROUTE_V2.API}`,
    withCredentials: false, // This is the default
    headers
});

type FilterDataEntry = {
    hideFilter: boolean;
    fieldName: string;
    resultCount: number;
    label: string;
    currentValue: object[];
    metaData: string[];
    values: object[];
};

type FilterData = {
    category: FilterDataEntry;
    courseFormat: FilterDataEntry;
    committeeType: FilterDataEntry;
    starterSeminars: FilterDataEntry;
    city: FilterDataEntry;
    speakers: FilterDataEntry;
    restrictToDistricts: FilterDataEntry;
    restrictToCountyDistricts: FilterDataEntry;
};

type Seminars = {
    data: {
        count: number;
        eventsCount: number;
        items: object[];
        filterData: FilterData;
    };
};

interface SeminarSearchPayload {
    offset: number;
    pageSize: number;
    fullTextSearch: string;
    category: string[];
    city: string[];
    courseFormat: string[];
    committeeType: string[];
    speakers: string[];
    starterSeminars: string[];
    fromDate: object;
    toDate: object;
    locationRegionId: number[];
}

export default {
    getSeminars(payload: SeminarSearchPayload): Promise<Seminars> {
        const {
            fullTextSearch,
            category,
            courseFormat,
            committeeType,
            speakers,
            starterSeminars,
            fromDate,
            toDate,
            city,
            locationRegionId,
            offset,
            pageSize
        } = payload;

        return apiClient.post(`${ROUTE.SEMINAR.SEARCH}`, {
            offset,
            pageSize,
            filter: {
                fullTextSearch,
                category,
                courseFormat,
                committeeType,
                speakers,
                starterSeminars,
                fromDate,
                toDate,
                city,
                locationRegionId
            }
        });
    },

    getCategories(): Promise<object[]> {
        return apiClient.get(ROUTE.SEMINAR.CATEGORIES);
    },

    getCourseFormats(): Promise<{ key: string; value: string }[]> {
        return apiClient.get(ROUTE.SEMINAR.FORMATS);
    },

    getCommitteeTypes(): Promise<{ key: string; value: string }[]> {
        return apiClient.get(ROUTE.SEMINAR.COMMITTEE);
    },

    getSpeakers(): Promise<object[]> {
        return apiClient.get(ROUTE.SEMINAR.SPEAKERS);
    },

    getLocations(): Promise<string[]> {
        return apiClient.get(ROUTE.SEMINAR.LOCATIONS);
    },

    getStarterSeminars(): Promise<string[]> {
        return apiClient.get(ROUTE.SEMINAR.STARTER_SEMINARS);
    },

    getRegions(): Promise<Region[]> {
        return apiClient.get(ROUTE.SEMINAR.REGIONS);
    },

    getDistricts(countyDistrictIds: number[]): Promise<{ id: number; name: string; fullName: string }[]> {
        return apiClientV2.get(ROUTE_V2.FILTER.DISTRICTS.INDEX, { params: { countyDistrictIds } });
    },

    getCountyDistricts(): Promise<{ id: number; name: string; fullName: string }[]> {
        return apiClientV2.get(ROUTE_V2.FILTER.COUNTY_DISTRICTS.INDEX);
    },

    getSeminarDetail(bwsBaseNumber: string): Promise<{ data: { seminar: object } }> {
        return apiClient.get(`${ROUTE.SEMINAR.DETAIL}/${bwsBaseNumber}`);
    },

    getSeminarUrl(bwsSeminarNumber: string): Promise<{ data: string }> {
        return apiClient.get(`${ROUTE.SEMINAR.SEMINAR_ROUTE_LOOKUP}/${bwsSeminarNumber}`);
    }
};
