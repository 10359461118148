






















import { PropType } from 'vue';
import { Nullable } from '@/types';
import buttonStyles from '@/helper/buttonStyles';
import GkBaseButtonIcon from '@/components/Base/GkBaseButtonIcon.vue';

export default {
    name: 'GkBaseButton',
    components: { GkBaseButtonIcon },

    props: {
        label: {
            type: String as PropType<string>,
            required: true
        },

        type: {
            type: String as PropType<string>,
            default: 'button'
        },

        loading: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        disabled: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        iconPositionRight: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        iconName: {
            type: String as PropType<Nullable<string>>,
            default: null
        },

        inverted: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        secondary: {
            type: Boolean as PropType<boolean>,
            default: false
        }
    },

    computed: {
        classNames() {
            return buttonStyles(this.secondary, this.inverted);
        },

        isDisabled() {
            return this.loading || this.disabled;
        }
    }
};
