import { jumpToTop } from '@/helper/jumpToTop';

/**
 * Apply reset
 *
 * @param dispatch
 * @param commit
 */
export default async ({ dispatch, commit }: any): Promise<void> => {
    try {
        await dispatch('resetFilter');
        await dispatch('seminarSearchResults/fetchSeminarResults', null, { root: true });
        commit('CHANGE_CURRENT_PAGE', 0);
    } finally {
        history.pushState({}, null, `${window.location.origin}${window.location.pathname}`);
        const resetEvent = new CustomEvent('resetFilter', { detail: 'reset' });
        document.dispatchEvent(resetEvent as CustomEvent);
        jumpToTop();
    }
};
