import { CollapsibleClassName, CollapsibleEventName } from './constants';
import { CollapsibleItemTitle } from './CollapsibleItemTitle';
import { CollapsibleItemContent } from './CollapsibleItemContent';
import stringToSelector from '@/helper/stringToSelector';

declare global {
    interface Event {
        detail: Element;
    }
}

export class CollapsibleItem {
    title: CollapsibleItemTitle;
    content: CollapsibleItemContent;
    titleElement: Element;

    constructor(public element: Element) {
        this._getTitleElement();
        this._getContentElement();
        this._addToggleEventListener();
    }

    private _getTitleElement(): void {
        this.titleElement = this.element.querySelector(stringToSelector(CollapsibleClassName.collapsibleItemTitle));

        this.title = new CollapsibleItemTitle(this.titleElement);
    }

    private _getContentElement(): void {
        const content = this.element.querySelector(stringToSelector(CollapsibleClassName.collapsibleItemContent));

        this.content = new CollapsibleItemContent(content);
    }

    private _addToggleEventListener(): void {
        document.addEventListener(CollapsibleEventName.toggleContentVisibilityEvent, event => {
            if (this.titleElement !== event.detail) return;

            this.toggle();
        });
    }

    toggle(): void {
        this.content.toggle();
    }
}
