






export default {
    name: 'GridContainer',

    props: {
        fluid: {
            type: Boolean,
            default: false
        },
        full: {
            type: Boolean,
            default: false
        }
    }
};
