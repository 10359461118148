export enum CollapsibleClassName {
    collapsibleElement = 'collapsible',
    collapsibleItem = 'collapsible__item',
    collapsibleItemTitle = 'collapsible__title',
    collapsibleItemContent = 'collapsible__content',
    collapsibleModifierClosed = 'collapsible--closed',
    collapsibleModifierOpen = 'collapsible--open',
    collapsibleModifierTransition = 'collapsible--transition',
    collapsibleModifierPersistent = 'collapsible--persistent',
    collapsibleModifierAccordion = 'collapsible--accordion'
}

export enum CollapsibleEventName {
    toggleContentVisibilityEvent = 'toggleCollapsibleContentVisibilityEvent'
}
