<template>
    <GridRow gridX paddingX>
        <GridCell width="12">
            <div :class="align">
                <v-progress-circular indeterminate color="primary" v-bind="$attrs" v-on="$listeners" />
            </div>
        </GridCell>
    </GridRow>
</template>

<script>
export default {
    name: 'BaseProgressCircular',

    props: {
        align: {
            type: String,
            required: false,
            default: 'flex-center'
        }
    }
};
</script>
