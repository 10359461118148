import { OvernightIdName, OverNightIds, SeminarPrices } from '@/helper/formValues';
import { de_DE } from '@/helper/translations';

export const getOvernightId = (overnightStay: string, optionPre: Boolean, optionPost: Boolean) => {
    const isOvernightStay = overnightStay === de_DE.PARTICIPANT_FORM_OVERNIGHT_OPTION_WITH;

    if (isOvernightStay && optionPre && optionPost) {
        return OvernightIdName.OvernightPrePost;
    }

    if (isOvernightStay && optionPre) {
        return OvernightIdName.OvernightPre;
    }

    if (isOvernightStay && optionPost) {
        return OvernightIdName.OvernightPost;
    }

    if (isOvernightStay) {
        return OvernightIdName.Overnight;
    }

    return OvernightIdName.NoOvernight;
};

export const calculateParticipantCosts = (overnightId: OverNightIds, seminar: SeminarPrices) => {
    const isWithOvernight = overnightId === OvernightIdName.Overnight;
    const isWithOvernightPost = overnightId === OvernightIdName.OvernightPost;
    const isWithOverNightPre = overnightId === OvernightIdName.OvernightPre;
    const isWithOverNightPreAndPost = overnightId === OvernightIdName.OvernightPrePost;

    let basePrice = seminar.cateringPrice;

    if (isWithOvernight || isWithOvernightPost || isWithOverNightPre || isWithOverNightPreAndPost) {
        basePrice += seminar.accommodationPrice;
    }

    if (isWithOvernightPost || isWithOverNightPreAndPost) {
        basePrice += seminar.nightAfterAccommodationPrice;
    }

    if (isWithOverNightPre || isWithOverNightPreAndPost) {
        basePrice += seminar.nightBeforeAccommodationPrice;
    }

    return basePrice;
};
