




import { PropType } from 'vue';
import { Nullable } from '@/types';

export default {
    name: 'GkBaseButtonIcon',

    props: {
        loading: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        iconPositionRight: {
            type: Boolean as PropType<boolean>,
            default: false
        },

        iconName: {
            type: String as PropType<Nullable<string>>,
            default: null
        }
    },

    computed: {
        iconSpacing() {
            return this.iconPositionRight ? 'ml-2' : 'mr-2';
        },

        classNames() {
            return `${this.iconSpacing} ${this.iconName}`;
        },

        loadingIcon() {
            return `fa-solid fa-spinner animate-spin ${this.iconSpacing}`;
        }
    }
};
