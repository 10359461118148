<template>
    <component :is="isComponent" :class="classes" v-bind="$attrs" v-on="$listeners">
        <slot />
    </component>
</template>

<script>
import Span from './BaseText/Span.vue';
import Division from './BaseText/Division.vue';
import Paragraph from './BaseText/Paragraph.vue';

export default {
    name: 'BaseText',

    components: {
        Span,
        Division,
        Paragraph
    },

    props: {
        type: {
            type: String,
            required: false,
            default: 'span'
        },

        color: {
            type: String,
            required: false,
            default: null
        },

        body: {
            type: String,
            required: false,
            default: '1'
        },

        overline: {
            type: Boolean,
            required: false,
            default: false
        },

        bold: {
            type: Boolean,
            required: false,
            default: false
        },

        italic: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    computed: {
        isComponent() {
            switch (this.type) {
                case 'div':
                    return Division;

                case 'p':
                    return Paragraph;

                default:
                    return Span;
            }
        },

        bodyClass() {
            return this.body === '2' ? 'copytext-sm' : 'copytext-default';
        },

        overlineClass() {
            return 'copytext-sm';
        },

        boldClass() {
            return 'font-bold';
        },

        italicClass() {
            return 'font-italic';
        },

        classes() {
            return [
                this.overline ? this.overlineClass : this.bodyClass,
                this.color,
                this.bold && this.boldClass,
                this.italic && this.italicClass
            ].filter(className => !!className);
        }
    }
};
</script>
