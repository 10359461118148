import { CourseFormatMutation } from '@/store/modules/seminarSearchFilterValues/types';

/**
 * Add course format filter
 *
 * @param state
 * @param commit
 * @param payload
 */

export default async ({ state, commit }, payload: CourseFormatMutation): Promise<void> => {
    if (!payload.value) {
        commit('REMOVE_ACTIVE_FILTER', payload.key);
    } else {
        commit('ADD_ACTIVE_FILTER', payload.key);
    }

    commit('FILTER_ADD_COURSE_FORMAT', payload);
};
