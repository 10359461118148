import SeminarService from '../../services/SeminarService';
import { findIndex } from 'lodash';
import { priceFormatter } from '@/helper/currency';
import { SeminarDetail, SeminarEventItem } from '@/components/organisms/seminar-details/types';
import { Nullable } from '@/types';

interface State {
    seminar: Nullable<SeminarDetail>;
    selectedEvent: number;
    event: SeminarEventItem[];
    loadingStatus: number;
    backLink: string;
}

export const state: State = {
    seminar: null,
    selectedEvent: 0,
    event: [],
    loadingStatus: 1,
    backLink: ''
};

export const mutations = {
    ADD_SEMINAR(state: any, seminar: SeminarDetail) {
        state.seminar = seminar;
    },
    SWITCH_EVENT(state: any, payload: any) {
        state.event = payload.events[payload.index];
        state.selectedEvent = state.event.id;
    },
    CHANGE_STATUS(state: any, status: Number) {
        state.loadingStatus = status;
    },
    ADD_BACK_LINK(state: any, backlink: string) {
        state.backLink = backlink;
    }
};

export const actions = {
    fetchSeminarDetail({ commit }: any, payLoad: any) {
        return new Promise((resolve, reject) => {
            commit('CHANGE_STATUS', 2);
            SeminarService.getSeminarDetail(payLoad.id)
                .then((response: any) => {
                    if (response.status === 200) {
                        commit('ADD_SEMINAR', response.data.seminar);
                        const events = response.data.seminar.events;
                        if (payLoad.variant) {
                            const index = findIndex(events, { seminarNumber: payLoad.variant });
                            commit('SWITCH_EVENT', { index: index, events: events });
                            resolve();
                        } else {
                            const validEvents = events.filter((item: any) => {
                                const fromDate = new Date(item.fromDate).getTime();
                                const today = new Date().getTime();
                                return fromDate > today;
                            });
                            commit('SWITCH_EVENT', { index: 0, events: validEvents });
                            resolve();
                        }
                        commit('CHANGE_STATUS', 3);
                    } else {
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                        commit('CHANGE_STATUS', 4);
                        reject();
                    }
                })
                .catch((err: any) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                    commit('CHANGE_STATUS', 4);
                    reject();
                });
        });
    },
    addBackLink({ commit }: any, backLink: string) {
        commit('ADD_BACK_LINK', backLink);
    }
};

export const getters = {
    seminarUrl: (state: any) => {
        return state.event.url;
    },
    seminarPrice: (state: any) => {
        return state.event.priceInEUR;
    },
    accomodationPrice: (state: any) => {
        return state.event.expenses ? priceFormatter(state.event.expenses) : null;
    },
    priceAccumulated: (state: any) => {
        const sum = ~~(state.event.priceInEUR + state.event.expenses);
        return `€ ${sum},-`;
    },
    seminarDuration: (state: any) => {
        if (state.event.duration > 1) {
            return `${state.event.duration} Tage`;
        }
        return `1 Tag`;
    },
    seminarInPast: (state: any) => {
        if (Date.now() >= Date.parse(state.event.toDate)) {
            return true;
        }
        return false;
    },
    seminarTopics: (state: any) => {
        const topics = [];

        for (const key in state.seminar.topics) {
            const value = state.seminar.topics[parseInt(key, 10)];
            const children = [];

            for (const subKey in value['subTopics']) {
                const subValue = value['subTopics'][parseInt(subKey, 10)];
                children.push({ id: parseInt(subKey, 10), name: subValue });
            }

            topics.push({ id: parseInt(key, 10), name: value.title, children });
        }

        return topics;
    }
};
