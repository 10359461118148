import { isEmpty } from 'lodash';

/**
 * Add text filter
 *
 * @param state
 * @param commit
 * @param searchTerm
 */
export default async ({ state, commit }, searchTerm: String = ''): Promise<void> => {
    commit('FILTER_ADD_SEARCH_TERM', searchTerm);

    if (isEmpty(searchTerm)) {
        commit('REMOVE_ACTIVE_FILTER', 'textFilter');
    } else {
        commit('ADD_ACTIVE_FILTER', 'textFilter');
    }
};
