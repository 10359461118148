import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export default {
    methods: {
        date(value): string {
            if (!value) return '';
            return dayjs(value).format('DD.MM.YYYY');
        },

        dateShort(value): string {
            if (!value) return '';
            return dayjs(value).format('DD.MM.YY');
        },

        dateShorter(value): string {
            if (!value) return '';
            return dayjs(value).format('DD.MM.');
        },

        time(value): string {
            if (!value) return '';
            return dayjs(value).format('HH:mm');
        },

        dateTime(value): string {
            if (!value) return '';
            return dayjs(value).format('DD.MM.YYYY, HH:mm');
        },

        year(value): string {
            if (!value) return '';
            return dayjs(value).format('YYYY');
        },

        customDate(value, format): string {
            if (!value) return '';
            return dayjs(value).format(format);
        },

        dateInFuture(value): boolean {
            if (!value) return false;
            return dayjs(value).isSameOrAfter(new Date(), 'day');
        }
    }
};
