import axios from 'axios';
import { ROUTE } from '../helper/routes';

const apiClient = axios.create({
    baseURL: `${ROUTE.API}`,
    withCredentials: false, // This is the default
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
    }
});

export default {
    add(itemId: Number, amount: Number) {
        return apiClient.post(`${ROUTE.CART.ADD}`, {
            itemId,
            amount
        });
    },
    delete(itemId: Number) {
        return apiClient.delete(`${ROUTE.CART.DELETE}/${itemId}`);
    },
    list() {
        return apiClient.get(`${ROUTE.CART.LIST}`);
    },
    update(items: any) {
        return apiClient.patch(`${ROUTE.CART.UPDATE}`, items);
    }
};
