import CartService from '@/services/CartServices';
import CheckoutService from '@/services/CheckoutServices';
import { Status } from '@/helper/status';
import { find, isEmpty, filter, sum } from 'lodash';
import { participantModel, billingModel, contactModel } from '@/helper/checkoutModel';
import { calculateParticipantCosts, getOvernightId } from '@/helper/overnightId';

export const state: any = {
    cartStatus: Status.Init,
    cart: {},
    itemAdded: Status.Init,
    itemDeleted: Status.Ready,
    cartUpdated: Status.Ready,
    orderStatus: Status.Init,
    formValidation: [],
    contactPerson: {},
    billingAddress: {},
    billingActive: false,
    activateForm: [],
    participants: [],
    validationMode: false,
    additionalCosts: [],
    links: {},
    panels: {}
};

export const mutations: any = {
    CHANGE_CART_STATUS(state: any, status: Number) {
        state.cartStatus = status;
    },
    CHANGE_CART_ADD_STATUS(state: any, status: Number) {
        state.itemAdded = status;
    },
    CHANGE_CART_DELETE_STATUS(state: any, status: Number) {
        state.itemDeleted = status;
    },
    CHANGE_CART_UPDATE_STATUS(state: any, status: Number) {
        state.cartUpdated = status;
    },
    CHANGE_ORDER_STATUS(state: any, status: Number) {
        state.orderStatus = status;
    },
    ADD_CART(state: any, cart: object[]) {
        state.cart = cart;
    },
    ADD_SEMINAR_ID(state: any, ids: string[]) {
        state.seminarIds = ids;
    },
    SAVE_CONTACT_PERSON(state: any, data: Object) {
        state.contactPerson = data;
    },
    SAVE_BILLING_ADDRESS(state: any, data: Object) {
        state.billingAddress = data;
    },
    CHANGE_FORM_VALIDATION(state: any, validation: object[]) {
        state.formValidation = validation;
    },
    SWITCH_BILLING_ADDRESS(state: any) {
        state.billingActive = !state.billingActive;
    },
    ACTIVATE_FORM(state: any, ids: string[]) {
        state.activateForm = ids;
    },
    SAVE_PARTICIPANT(state: any, participants: object[]) {
        state.participants = participants;
    },
    RESET_CHECKOUT(state: any) {
        state.cartStatus = Status.Init;
        state.cart = [];
        state.itemAdded = Status.Init;
        state.itemDeleted = Status.Init;
        state.cartUpdated = Status.Init;
        state.formValidation = [];
        state.contactPerson = {};
        state.billingAddress = {};
        state.billingActive = false;
        state.activateForm = [];
        state.participants = [];
    },
    SWITCH_VALIDATION_MODE(state: any, mode: Boolean) {
        state.validationMode = mode;
    },
    SET_ADDITIONAL_COSTS(state: any, additionalCosts: object[]) {
        state.additionalCosts = additionalCosts;
    },
    SET_PARTICIPANT_COSTS(state: any, key: string, value: number) {
        this.state.additionalCosts[key].push(value);
    },
    SET_LINKS(state: any, links: object) {
        state.links = links;
    },
    SET_PANEL_ID(state: any, id: any) {
        state.panels[id] = null;
    },
    SET_ACTIVE_PANEL(state: any, payload: any) {
        state.panels[payload.id] = payload.activePanel;
    }
};

interface updateCart {
    seminarId: number;
    amount: number;
}

export const actions: any = {
    fetchCart({ commit }: any) {
        return new Promise((resolve, reject) => {
            CartService.list()
                .then((response: any) => {
                    if (response.status === 200) {
                        commit('ADD_CART', response.data);
                        resolve();
                    } else {
                        commit('CHANGE_CART_STATUS', Status.Error);
                        reject();
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                    }
                })
                .catch((err: any) => {
                    commit('CHANGE_CART_STATUS', Status.Error);
                    reject();
                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        });
    },

    addToCart({ commit }: any, payload: object) {
        return new Promise((resolve, reject) => {
            CartService.add(payload['itemId'], payload['amount'])
                .then((response: any) => {
                    if (response.status === 200) {
                        resolve();
                    } else {
                        reject();
                        commit('CHANGE_CART_ADD_STATUS', Status.Error);
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                    }
                })
                .catch((err: any) => {
                    reject();
                    commit('CHANGE_CART_ADD_STATUS', Status.Error);
                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        });
    },

    addItemToCart({ commit, dispatch }: any, payload: object) {
        commit('CHANGE_CART_ADD_STATUS', Status.Loading);
        dispatch('addToCart', payload).then(() => {
            dispatch('loadCheckout');
            commit('CHANGE_CART_ADD_STATUS', Status.Ready);
        });
    },

    deleteFromCart({ commit }: any, itemId: Number) {
        return new Promise((resolve, reject) => {
            CartService.delete(itemId)
                .then((response: any) => {
                    if (response.status === 200) {
                        resolve();
                    } else {
                        commit('CHANGE_CART_DELETE_STATUS', Status.Error);
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                        reject();
                    }
                })
                .catch((err: any) => {
                    commit('CHANGE_CART_DELETE_STATUS', Status.Error);
                    // eslint-disable-next-line no-console
                    console.error(err);
                    reject();
                });
        });
    },

    updateCart({ commit }: any, items: object[]) {
        return new Promise((resolve, reject) => {
            CartService.update(items)
                .then((response: any) => {
                    if (response.status === 200) {
                        resolve();
                    } else {
                        commit('CHANGE_CART_UPDATE_STATUS', Status.Error);
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                        reject();
                    }
                })
                .catch((err: any) => {
                    commit('CHANGE_CART_UPDATE_STATUS', Status.Error);
                    // eslint-disable-next-line no-console
                    console.error(err);
                    reject();
                });
        });
    },

    saveParticipantData({ commit, state }: any, participant: Object) {
        const currentParticipants = state.participants;
        const participants = [];

        const existingParticipant = find(currentParticipants, {
            seminarId: participant['seminarId'],
            userId: participant['userId']
        });

        if (existingParticipant) {
            for (const item of currentParticipants) {
                if (item.seminarId === participant['seminarId'] && item.userId === participant['userId']) {
                    continue;
                } else {
                    participants.push(item);
                }
            }
        } else {
            participants.push(...currentParticipants);
        }

        participants.push(participant);

        commit('SAVE_PARTICIPANT', participants);
    },

    saveContactData({ commit }: any, data: Object) {
        commit('SAVE_CONTACT_PERSON', data);
    },

    saveBillingData({ commit }: any, data: Object) {
        commit('SAVE_BILLING_ADDRESS', data);
    },

    validateForm({ commit, state }: any, payload: Object) {
        let globalValidation = state.formValidation;
        const existingValidation = find(globalValidation, { seminarId: payload['seminarId'], user: payload['user'] });
        if (existingValidation) {
            globalValidation = globalValidation.filter((value: any) => {
                return value !== existingValidation;
            });
        }

        globalValidation.push(payload);
        commit('CHANGE_FORM_VALIDATION', globalValidation);
    },

    validateFormInit({ commit }: any) {
        const cartItems = Object.values(state.cart.items);

        const initValidation = [];

        initValidation.push({
            seminarId: 0,
            user: 0,
            valid: !isEmpty(state.contactPerson)
        });

        initValidation.push({
            seminarId: -1,
            user: -1,
            valid: true
        });

        if (!isEmpty(cartItems)) {
            cartItems.forEach(cartItem => {
                for (let amount = 0; amount < cartItem['amount']; amount++) {
                    const itemExists = !isEmpty(
                        find(state.formValidation, { seminarId: cartItem['itemId'], user: amount + 1, valid: true })
                    );

                    const validForm = {
                        seminarId: cartItem['itemId'],
                        user: amount + 1,
                        valid: itemExists
                    };

                    initValidation.push(validForm);
                }
            });
        }

        commit('CHANGE_FORM_VALIDATION', initValidation);
    },

    loadCheckout({ state, dispatch, commit }: any) {
        commit('CHANGE_CART_STATUS', Status.Loading);
        return dispatch('fetchCart').then(async () => {
            if (!state.cart.itemsCount) {
                commit('SAVE_CONTACT_PERSON', {});
                commit('SAVE_BILLING_ADDRESS', {});
                commit('SAVE_PARTICIPANT', []);
                commit('CHANGE_FORM_VALIDATION', []);
            }

            const timeout = setTimeout(async () => {
                await dispatch('validateFormInit');
                clearTimeout(timeout);
            }, 500);

            commit('CHANGE_CART_STATUS', Status.Ready);
        });
    },

    addToCheckout({ commit, getters }: any) {
        commit('CHANGE_ORDER_STATUS', Status.Loading);
        const { checkoutData } = getters;
        CheckoutService.add(checkoutData)
            .then((response: any) => {
                if (response.status === 200) {
                    commit('CHANGE_ORDER_STATUS', Status.Sent);
                    commit('RESET_CHECKOUT');
                } else {
                    commit('CHANGE_ORDER_STATUS', Status.Error);
                    // eslint-disable-next-line no-console
                    console.error('Error: ' + response.status);
                }
            })
            .catch((err: any) => {
                commit('CHANGE_ORDER_STATUS', Status.Error);
                // eslint-disable-next-line no-console
                console.error(err);
            });
    },

    switchBilling({ commit }: any) {
        commit('SWITCH_BILLING_ADDRESS');
    },

    activateForm({ commit, state }: any, id: Number) {
        const currentForms = state.activateForm;

        if (!currentForms.includes(id)) {
            currentForms.push(id);
        }

        commit('ACTIVATE_FORM', currentForms);
    },

    deactivateForm({ commit, state }: any, id: Number) {
        const currentForms = state.activateForm;

        const filteredForms = currentForms.filter((value: any) => {
            return value !== id;
        });

        commit('ACTIVATE_FORM', filteredForms);
    },

    saveParticipant({ dispatch }: any, data: Object) {
        const seminarId = data['seminarId'];
        dispatch('saveParticipantData', data);
        dispatch('deactivateForm', seminarId);
    },

    switchValidationMode({ commit }: any, mode: Boolean) {
        commit('SWITCH_VALIDATION_MODE', mode);
    },

    changeOrderStatus({ commit }: any, status: Number) {
        commit('CHANGE_ORDER_STATUS', status);
    },

    deleteSeminar({ dispatch, commit }: any, seminarId: number) {
        commit('CHANGE_CART_DELETE_STATUS', Status.Loading);
        dispatch('deleteFromCart', seminarId).then(() => {
            dispatch('fetchCart').then(() => {
                if (!state.cart.itemsCount) {
                    commit('SAVE_CONTACT_PERSON', {});
                    commit('SAVE_BILLING_ADDRESS', {});
                    commit('SAVE_PARTICIPANT', []);
                    commit('CHANGE_FORM_VALIDATION', []);
                }
                dispatch('validateFormInit');
                commit('CHANGE_CART_DELETE_STATUS', Status.Ready);
            });
        });
    },

    changeAmount({ dispatch, commit }: any, payload: updateCart) {
        return new Promise(resolve => {
            commit('CHANGE_CART_UPDATE_STATUS', Status.Loading);
            const newAmount = {
                items: [
                    {
                        itemId: payload.seminarId,
                        amount: payload.amount
                    }
                ]
            };
            dispatch('updateCart', newAmount).then(() => {
                dispatch('fetchCart').then(() => {
                    dispatch('validateFormInit');
                    commit('CHANGE_CART_UPDATE_STATUS', Status.Ready);
                    resolve();
                });
            });
        });
    },

    saveLinks({ commit }: any, links: object) {
        commit('SET_LINKS', links);
    }
};

export const getters: any = {
    hasCheckoutSeminars: (state: any) => {
        if (!('items' in state.cart)) return false;
        return !isEmpty(state.cart.items);
    },

    checkoutSeminars: (state: any, getters: any) => {
        if (!getters.hasCheckoutSeminars) return {};

        return state.cart.items;
    },

    itemAdded: (state: any) => {
        return state.itemAdded;
    },

    checkoutItems: (state: any, getters: any) => {
        const participants = [...state.participants];
        const seminarIds = [];
        const items = [];
        const overnightIds = getters.overnightIds;

        for (const participant of participants) {
            const seminarId = participant['seminarId'];
            if (!seminarIds.includes(seminarId)) {
                seminarIds.push(seminarId);
            }
        }

        for (const seminarId of seminarIds) {
            const item = { seminarId, participants: [] };
            const seminarParticipants = filter(participants, { seminarId });
            for (const participant of seminarParticipants) {
                const overnightId = find(overnightIds, { seminarId, userId: participant.userId });
                item['participants'].push(participantModel(participant, overnightId.overnightId));
            }

            items.push(item);
        }

        return items;
    },

    checkoutItemsCount: (state: any) => {
        return state.cart.itemsCount;
    },

    checkoutData: (state: any, getters: any) => {
        return {
            contactPerson: contactModel(state.contactPerson),
            billingAddress: billingModel(state.billingActive ? state.billingAddress : state.contactPerson),
            items: getters.checkoutItems
        };
    },

    globalValidation: (state: any) => {
        if (isEmpty(state.formValidation)) return false;

        return state.formValidation.every(item => item.valid);
    },

    formsComplete: (state: any) => {
        const seminarIds = [];
        const completedForms = {};
        const activeForms = state.activateForm;

        for (const item of state.formValidation) {
            if (!seminarIds.includes(item.seminarId) && item.seminarId !== 0 && item.seminarId !== -1) {
                seminarIds.push(item.seminarId);
            }
        }

        for (const seminarId of seminarIds) {
            let isComplete: Boolean = false;
            completedForms[seminarId] = isComplete;
            for (const item of state.formValidation) {
                if (item.seminarId === seminarId) {
                    if (!item.valid) {
                        completedForms[seminarId] = false;
                        break;
                    } else {
                        if (activeForms.includes(seminarId)) {
                            completedForms[seminarId] = false;
                        } else {
                            completedForms[seminarId] = true;
                        }
                    }
                }
            }
        }

        return completedForms;
    },

    overnightIds: (state: any) => {
        const renderSeminar = (seminarId: number) => {
            return find(state.cart.items, { itemId: seminarId });
        };

        return state.participants.map(participant => {
            return {
                seminarId: participant.seminarId,
                userId: participant.userId,
                overnightId: getOvernightId(
                    participant.overnightStay,
                    participant.overnightStayPre,
                    participant.overnightStayPost
                ),
                accommodationPrice: renderSeminar(participant.seminarId).accommodationPrice,
                cateringPrice: renderSeminar(participant.seminarId).cateringPrice
            };
        });
    },
    additionalCostsSum: (state: any, getters: any) => {
        if (!getters.overnightIds) return null;

        const additionalCosts = getters.overnightIds.map(participant => {
            if (participant.overnightId === 'Ohne Übernachtung') return participant.cateringPrice;

            return participant.accommodationPrice + participant.cateringPrice;
        });

        return sum(additionalCosts);
    }
};
