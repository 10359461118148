<template>
    <h5 v-bind="$attrs" v-on="$listeners">
        <slot />
    </h5>
</template>

<script>
export default {
    name: 'H5'
};
</script>
