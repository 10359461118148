import { isEmpty } from 'lodash';

/**
 * Add starter seminar filter
 *
 * @param state
 * @param commit
 * @param categoryNames
 */
export default async ({ state, commit }, categoryNames: string[] = []) => {
    commit('FILTER_ADD_STARTER_SEMINARS', categoryNames);

    if (isEmpty(categoryNames)) {
        commit('REMOVE_ACTIVE_FILTER', 'starterSeminar');
    } else {
        commit('ADD_ACTIVE_FILTER', 'starterSeminar');
    }
};
