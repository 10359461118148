<template>
    <img :src="logo" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
import logo from '@/assets/img/igbce_bws_logo.svg';

export default {
    name: 'BaseLogo',

    computed: {
        logo() {
            return logo;
        }
    }
};
</script>
