import Vue from 'vue';
import Vuex from 'vuex';
import * as seminarDetail from './modules/seminarDetail';
import * as checkout from './modules/checkout';
import * as i18n from './modules/i18n';
import * as speakers from './modules/speakers';
import * as seminarSearchFilterOptions from './modules/seminarSearchFilterOptions';
import * as seminarSearchResults from './modules/seminarSearchResults';
import * as seminarSearchFilterValues from './modules/seminarSearchFilterValues';
import * as mainNavigation from './modules/mainNavigation';
import { readFromStorage } from '@/helper/localStorage';
import { CACHE_ITEMS } from '@/helper/caching';
import storageNames from '@/data/storageNames';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        seminarDetail,
        checkout,
        i18n,
        speakers,
        seminarSearchFilterOptions,
        seminarSearchResults,
        seminarSearchFilterValues,
        mainNavigation
    },
    mutations: {
        INITIALIZE_STATE(state: any, cachedState: any) {
            for (const [key, stateItem] of Object.entries(state)) {
                const values: any = stateItem;

                for (const item in values) {
                    if (CACHE_ITEMS.includes(item)) {
                        state[key][item] = cachedState[key][item];
                    }
                }
            }
        }
    },

    actions: {
        initializeState({ commit }: any) {
            const cachedState = readFromStorage({ key: storageNames.VUEX_STORE });
            if (cachedState) {
                commit('INITIALIZE_STATE', cachedState);
            }
        }
    }
});
