<template>
    <div v-on="$listeners" v-bind="$attrs" :style="styles"></div>
</template>

<script>
export default {
    name: 'BaseDivider',

    props: {
        dashed: {
            type: Boolean,
            required: false,
            default: false
        },

        color: {
            type: String,
            required: false,
            default: 'gray400'
        },

        size: {
            type: [String, Number],
            required: false,
            default: 1
        }
    },

    computed: {
        styles() {
            return {
                borderBottomColor: this.$vuetify.theme.themes.light[this.color],
                borderBottomWidth: `${this.size}px`,
                borderBottomStyle: this.dashed ? 'dashed' : 'solid'
            };
        }
    }
};
</script>
