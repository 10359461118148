<template>
    <span>{{ location }}</span>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'BaseSeminarLocation',

    props: {
        seminarLocation: {
            type: undefined,
            required: true,
            default: () => ({
                isPublished: false
            })
        },

        seminarType: {
            type: undefined,
            required: true,
            default: null
        }
    },

    computed: {
        ...mapGetters(['i18n']),

        hasSeminarType() {
            return !!this.seminarType;
        },

        hasSeminarLocation() {
            return !!this.seminarLocation;
        },

        isPresenceSeminar() {
            if (!this.hasSeminarType) return false;
            return this.seminarType === 'presence-seminar' || this.seminarType === 'blended-learning';
        },

        isWebSeminar() {
            if (!this.hasSeminarType) return false;
            return this.seminarType === 'e-learning';
        },

        location() {
            const tbaLabel = this.i18n.BASE.SEMINAR.LOCATION.TBA;

            if (this.isPresenceSeminar && this.hasSeminarLocation) {
                return this.seminarLocation.city;
            }

            if (this.isWebSeminar) {
                return this.i18n.BASE.SEMINAR.LOCATION.WEB_SEMINAR;
            }

            return tbaLabel;
        }
    }
};
</script>
