<template>
    <div v-bind="$attrs" v-on="$listeners">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Division'
};
</script>
