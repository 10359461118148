import { CollapsibleClassName, CollapsibleEventName } from './constants';
import { CollapsibleItem } from './CollapsibleItem';
import stringToSelector from '@/helper/stringToSelector';

export class CollapsibleElement {
    private _collapsibleItems: CollapsibleItem[] = [];

    constructor(public element: Element) {
        this._initializeCollapsibleItems();
        this._addToggleEventListener();
    }

    private _initializeCollapsibleItems(): void {
        const collapsibleItems = this.element.querySelectorAll(stringToSelector(CollapsibleClassName.collapsibleItem));

        collapsibleItems.forEach(element => {
            this._collapsibleItems.push(new CollapsibleItem(element));
        });
    }

    private _isAccordion(): boolean {
        return this.element.classList.contains(CollapsibleClassName.collapsibleModifierAccordion);
    }

    /**
     * This event listener closes all non-clicked items in accordion mode
     * @private
     */
    private _addToggleEventListener(): void {
        if (!this._isAccordion()) return;

        document.addEventListener(CollapsibleEventName.toggleContentVisibilityEvent, event => {
            this._collapsibleItems.forEach(({ titleElement, content, title }) => {
                if (titleElement === event.detail) return;
                if (!this.element.contains(event.detail.parentNode)) return;

                content.hide();
                title.hide();
            });
        });
    }
}
