import { CollapsibleClassName } from './constants';

interface CollapsibleElementClass {
    isVisible: () => boolean;
    show: () => void;
    hide: () => void;
    toggle: () => void;
}

export class CollapsibleItemElement implements CollapsibleElementClass {
    constructor(public element: Element) {}

    isVisible(): boolean {
        return this.element.classList.contains(CollapsibleClassName.collapsibleModifierOpen);
    }

    show(): void {
        this.element.classList.add(CollapsibleClassName.collapsibleModifierOpen);
        this.element.classList.remove(CollapsibleClassName.collapsibleModifierClosed);
    }

    hide(): void {
        this.element.classList.add(CollapsibleClassName.collapsibleModifierClosed);
        this.element.classList.remove(CollapsibleClassName.collapsibleModifierOpen);
    }

    toggle(): void {
        this.element.classList.add(CollapsibleClassName.collapsibleModifierTransition);
        if (this.isVisible()) {
            this.hide();
        } else {
            this.show();
        }
    }
}
