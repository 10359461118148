<template>
    <h2 v-bind="$attrs" v-on="$listeners">
        <slot />
    </h2>
</template>

<script>
export default {
    name: 'H2'
};
</script>
