import { de_DE } from '@/helper/translations';

export const state: any = {
    ...de_DE
};

export const getters: any = {
    i18n: (state: any) => {
        return state;
    }
};
