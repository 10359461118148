export default async ({ commit }: any, { callback = null, mutation = null } = {}): Promise<void> => {
    if (!callback || !mutation) return;

    try {
        const { data } = await callback();
        commit(mutation, data);
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
