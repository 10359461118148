










import { getCourseFormatIcon } from '@/helper/courseFormatIcon';
import IconFont from '@/components/atoms/icon-fonts/IconFont.vue';

export default {
    name: 'BaseSeminarTypeIcon',

    components: {
        IconFont
    },

    props: {
        seminarType: {
            type: String,
            required: true
        },

        iconSize: {
            type: [String],
            required: false,
            default: '12'
        },

        iconColor: {
            type: [String],
            required: false,
            default: 'grey-700'
        }
    },

    computed: {
        courseFormatIcon() {
            return getCourseFormatIcon(this.seminarType);
        }
    }
};
