import { isEmpty } from 'lodash';

/**
 * Add location filter
 *
 * @param state
 * @param commit
 * @param locationCities
 */
export default async ({ state, commit }, locationCities: string[] = []) => {
    commit('FILTER_ADD_LOCATION', locationCities);

    if (isEmpty(locationCities)) {
        commit('REMOVE_ACTIVE_FILTER', 'location');
    } else {
        commit('ADD_ACTIVE_FILTER', 'location');
    }
};
