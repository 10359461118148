<template>
    <v-dialog ref="dialog" v-model="modal" persistent width="290px">
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="formattedDate"
                :label="fieldLabel"
                readonly
                v-bind="attrs"
                v-on="on"
                solo
                flat
                :rules="validation"
                background-color="#F6F6F6"
                color="#676767"
                hide-details
                height="56px"
            >
            </v-text-field>
        </template>

        <v-date-picker v-model="rawDate" scrollable :max="maxDate" :min="minDate">
            <v-spacer></v-spacer>

            <v-btn text color="primary" @click="saveDate">
                {{ i18n.BASE_DATE_PICKER_BUTTON_LABEL_OK }}
            </v-btn>
        </v-date-picker>
    </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import dateHelper from '@/helper/dateHelper';
import validationRules from '@/mixins/validationRules';
import dayjs from 'dayjs';

export default {
    name: 'BaseDatePicker',

    mixins: [validationRules],

    data() {
        return {
            rawDate: '',
            menu: false,
            modal: false,
            maxDate: null,
            minDate: null
        };
    },

    props: {
        label: {
            type: String,
            require: true,
            default: ''
        },

        value: {
            type: [Date, String],
            require: false,
            default: new Date()
        },

        max: {
            type: [Date, String],
            require: false,
            default: null
        },

        min: {
            type: [Date, String],
            require: false,
            default: null
        },

        required: {
            type: Boolean,
            default: false,
            required: false
        },

        rules: {
            type: Array,
            required: false
        },

        hideIcon: {
            type: Boolean,
            default: false,
            required: false
        },

        attach: {
            type: String,
            required: false,
            default: '#app'
        }
    },

    computed: {
        ...mapGetters(['i18n']),

        formattedDate() {
            return dayjs(this.value).format('DD.MM.YYYY');
        },

        validation() {
            if (this.rules) return this.rules;
            if (this.required) return this.requiredRules;

            return undefined;
        },

        fieldLabel() {
            if (this.required) return `${this.label} *`;
            return this.label;
        }
    },

    watch: {
        value(value) {
            this.rawDate = dayjs(value).format('YYYY-MM-DD');
        },

        min(value) {
            if (!value) {
                this.minDate = value;
            } else {
                this.minDate = dayjs(value).format('YYYY-MM-DD');
            }
        },

        max(value) {
            if (!value) {
                this.maxDate = value;
            } else {
                this.maxDate = dayjs(value).format('YYYY-MM-DD');
            }
        },

        modal(value) {
            if (!value) this.$emit('close');

            this.$emit('open');
        }
    },

    methods: {
        saveDate() {
            this.$emit('input', new Date(this.rawDate));
            this.modal = false;
        }
    },

    mounted() {
        this.rawDate = dayjs(this.value).format('YYYY-MM-DD');
    }
};
</script>
