<template>
    <h4 v-bind="$attrs" v-on="$listeners">
        <slot />
    </h4>
</template>

<script>
export default {
    name: 'H4'
};
</script>
