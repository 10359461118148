import { CollapsibleClassName, CollapsibleEventName } from './constants';
import { CollapsibleItemElement } from './CollapsibleItemElement';

export class CollapsibleItemTitle extends CollapsibleItemElement {
    private readonly _toggleContentVisibilityEvent: CustomEvent;

    constructor(public element: Element) {
        super(element);
        this._toggleContentVisibilityEvent = new CustomEvent(CollapsibleEventName.toggleContentVisibilityEvent, {
            detail: this.element
        });
        this._addClickEvent();
    }

    private _isPersistent(): boolean {
        return this.element.classList.contains(CollapsibleClassName.collapsibleModifierPersistent);
    }

    private _dispatchContentVisibilityEvent(): void {
        document.dispatchEvent(this._toggleContentVisibilityEvent);
    }

    private _addClickEvent(): void {
        if (this._isPersistent()) return;

        this.element.addEventListener('click', () => {
            this._dispatchContentVisibilityEvent();
            this.toggle();
        });
    }
}
