<template>
    <span @click="goTo"><slot /></span>
</template>
<script>
export default {
    name: 'nav-item',

    props: {
        navItem: {
            type: Object,
            required: true
        }
    },

    methods: {
        async goTo() {
            if ('to' in this.navItem) {
                try {
                    await this.$router.push(this.navItem.to);
                } catch {
                    return;
                }
            }

            if ('url' in this.navItem) {
                if ('target' in this.navItem) {
                    if (this.navItem.target === '_blank') {
                        return window.open(this.navItem.url);
                    }
                }
                location.assign(this.navItem.url);
            }

            if ('command' in this.navItem) {
                this.navItem.command();
            }
        }
    }
};
</script>
