/**
 * Add start date filter
 *
 * @param commit
 * @param dateRange
 */
export default async ({ commit }, dateRange: string[]): Promise<void> => {
    commit('FILTER_ADD_DATE_RANGE', dateRange);
    commit('ADD_ACTIVE_FILTER', 'dateRange');
};
