import dayjs from 'dayjs';

const hasWhiteSpace = (value: string) => {
    if (typeof value === 'object') return false;
    return /\s/g.test(value);
};

const hasOnlyWhiteSpace = (value: string) => {
    if (hasWhiteSpace(value)) {
        if (value.replace(/\s/g, '') === '') {
            return true;
        }
    }
    return false;
};

const removeEmptyString = (formItem: Object) => {
    for (const item in formItem) {
        if (formItem[item] === '' || formItem[item] === undefined || hasOnlyWhiteSpace(formItem[item])) {
            formItem[item] = null;
        }
    }
};

export const participantModel = (participant: Object, overnightId: Number) => {
    removeEmptyString(participant);

    return {
        specialWishes: participant['specialWishes'],
        country: participant['country'],
        mobileNumber: participant['mobileNumber'],
        membershipType: participant['membershipType'],
        membershipNumber: participant['membershipNumber'],
        company: participant['company'],
        gender: participant['gender'],
        salutation: participant['salutation'],
        firstName: participant['firstName'],
        lastName: participant['lastName'],
        street: participant['street'],
        houseNumber: participant['houseNumber'],
        zip: participant['zip'],
        city: participant['city'],
        email: participant['email'],
        phoneNumber: participant['phoneNumber'],
        stayType: overnightId
    };
};

export const billingModel = (billing: Object) => {
    removeEmptyString(billing);

    return {
        country: billing['country'],
        company: billing['company'],
        gender: billing['gender'],
        salutation: billing['salutation'],
        firstName: billing['firstName'],
        lastName: billing['lastName'],
        street: billing['street'],
        houseNumber: billing['houseNumber'],
        zip: billing['zip'],
        city: billing['city'],
        department: billing['department'],
        email: billing['email']
    };
};

export const contactModel = (contact: Object) => {
    removeEmptyString(contact);

    return {
        company: contact['company'],
        gender: contact['gender'],
        salutation: contact['salutation'],
        firstName: contact['firstName'],
        lastName: contact['lastName'],
        street: contact['street'],
        houseNumber: contact['houseNumber'],
        zip: contact['zip'],
        city: contact['city'],
        email: contact['email'],
        phoneNumber: contact['phoneNumber']
    };
};

export const orderModel = (order: Object) => {
    removeEmptyString(order);
    removeEmptyString(order['company']);
    removeEmptyString(order['contactPerson']);

    return {
        seminarTopic: order['seminarTopic'],
        membersCount: order['membersCount'],
        dateFrom: order['dateFrom'],
        dateTo: order['dateTo'],
        location: order['location'],
        company: {
            name: order['company']['name'],
            street: order['company']['street'],
            houseNumber: order['company']['houseNumber'],
            zip: order['company']['zip'],
            city: order['company']['city']
        },
        contactPerson: {
            gender: order['contactPerson']['gender'],
            title: order['contactPerson']['title'],
            firstName: order['contactPerson']['firstName'],
            lastName: order['contactPerson']['lastName'],
            phoneNumber: order['contactPerson']['phoneNumber'],
            email: order['contactPerson']['email']
        }
    };
};
