import { CollapsibleClassName } from './constants';
import { CollapsibleElement } from './CollapsibleElement';
import stringToSelector from '@/helper/stringToSelector';

/**
 * This class collects all collapsible elements on a page.
 * It thus should be initialized globally
 */
export class CollapsibleElements {
    private _elements: CollapsibleElement[] = [];

    initialize(): void {
        this._initializeCollapsibleElements();
    }

    private _initializeCollapsibleElements(): void {
        const elements = document.querySelectorAll(stringToSelector(CollapsibleClassName.collapsibleElement));

        if (!elements) return;

        elements.forEach(element => {
            this._elements.push(new CollapsibleElement(element));
        });
    }
}
