import axios from 'axios';
import { ROUTE } from '../helper/routes';

const apiClient = axios.create({
    baseURL: `${ROUTE.API}`,
    withCredentials: false, // This is the default
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
        'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
    }
});

export default {
    add(order: Object) {
        return apiClient.post(`${ROUTE.CHECKOUT.ADD}`, order);
    }
};
