import SpeakerServices from '@/services/SpeakerServices';
import { Status } from '@/helper/status';

export const state: any = {
    searchStatus: Status.Init,
    searchResults: {}
};

export const mutations: any = {
    SPEAKER_CHANGE_SEARCH_STATUS(state: any, status: String) {
        state.searchStatus = status;
    },
    SPEAKER_ADD_SEARCH_DATA(state: any, searchResults: Object) {
        state.searchResults = searchResults;
    }
};

export const actions: any = {
    searchSpeaker({ commit }: any, searchTerm: string) {
        commit('SPEAKER_CHANGE_SEARCH_STATUS', Status.Loading);
        commit('SPEAKER_ADD_SEARCH_DATA', {});
        return new Promise((resolve, reject) => {
            SpeakerServices.searchSpeaker(searchTerm)
                .then((response: any) => {
                    if (response.status === 200) {
                        commit('SPEAKER_ADD_SEARCH_DATA', response.data);
                        resolve(true);
                        commit('SPEAKER_CHANGE_SEARCH_STATUS', Status.Ready);
                    } else {
                        commit('SPEAKER_CHANGE_SEARCH_STATUS', Status.Error);
                        reject();
                        // eslint-disable-next-line no-console
                        console.error('Error: ' + response.status);
                    }
                })
                .catch((err: any) => {
                    commit('SPEAKER_CHANGE_SEARCH_STATUS', Status.Error);
                    reject();
                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        });
    },
    resetSpeakerSearch({ commit }: any) {
        commit('SPEAKER_ADD_SEARCH_DATA', {});
        commit('SPEAKER_CHANGE_SEARCH_STATUS', Status.Init);
    }
};

export const getters: any = {};
