import Vue from 'vue';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import vuetify from '@/plugins/vuetify';
import store from './store';
import '@/scss-global/main.scss';
import GridContainer from '@/components/atoms/grid/GridContainer.vue';
import GridRow from '@/components/atoms/grid/GridRow.vue';
import GridCell from '@/components/atoms/grid/GridCell.vue';
import equalHeights from '@/js/_helpers/equalHeights';
import { mapActions } from 'vuex';
import disableDownload from '@/helper/disableImageDownload';
import baseComponentsRegistration from '@/helper/baseComponentsRegistration';
import splitComponents from '@/components/split-components';
import initCollapsible from '@/components/molecules/collapsible';
import router from '@/router';
import initializeGoogleAnalytics from '@/helper/initializeGoogleAnalytics';
import domLoaded from '@/helper/domLoaded';

Vue.config.productionTip = false;

Vue.component('GridContainer', GridContainer);
Vue.component('GridRow', GridRow);
Vue.component('GridCell', GridCell);

// Register base components
baseComponentsRegistration(Vue);

const initVue = (element: HTMLElement): void => {
    new Vue({
        el: element,
        store,
        vuetify,
        router,
        methods: {
            ...mapActions('seminarSearchFilterOptions', ['fetchAllFilterOptions'])
        },
        mounted() {},

        components: splitComponents
    });
};

const initVueElements = async (): Promise<void> => {
    equalHeights();
    disableDownload();
    initCollapsible();
    await initializeGoogleAnalytics();

    domLoaded(() => {
        const vueAppElements = <NodeListOf<HTMLElement>>document.querySelectorAll('#vueApp');

        if (!vueAppElements) return;

        vueAppElements.forEach(vueElement => {
            initVue(vueElement);
        });
    });
};

initVueElements();

Vue.config.devtools = true;
