import { isEmpty } from 'lodash';

/**
 * Add region filter
 *
 * @param state
 * @param commit
 * @param regionNames
 */
export default async ({ state, commit }, regionNames: string[] = []): Promise<void> => {
    commit('FILTER_ADD_REGION', regionNames);

    if (isEmpty(regionNames)) {
        commit('REMOVE_ACTIVE_FILTER', 'region');
    } else {
        commit('ADD_ACTIVE_FILTER', 'region');
    }
};
