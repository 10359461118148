import { mapGetters } from 'vuex';
import regex from '@/data/regex';

export default {
    computed: {
        ...mapGetters('i18n', ['i18n']),

        zipRules() {
            return [v => regex.zipCodeGermany.test(v) || this.i18n.FORM_VALIDATION_ZIP];
        },

        emailRules() {
            return [v => regex.email.test(v) || this.i18n.FORM_VALIDATION_EMAIL];
        },

        membershipNumberRules() {
            return [v => regex.membershipNumber.test(v) || this.i18n.FORM_VALIDATION_BWS_MEMBERSHIP];
        },

        seminarNumberRules() {
            return [v => regex.seminarNumber.test(v) || this.i18n.FORM_VALIDATION_SEMINAR_NUMBER];
        },

        requiredRules() {
            return [v => !!v || this.i18n.FORM_VALIDATION_REQUIRED];
        },

        mfaTokenRules() {
            return [v => regex.mfaToken.test(v) || this.i18n.FORM_VALIDATION_MFA];
        },

        passwordRules() {
            return [v => regex.passwordPolicy.test(v) || this.i18n.ACTIVATE_FORM_POLICY_ALL];
        }
    }
};
