export const CACHE_ITEMS = [
    'contactPerson',
    'billingAddress',
    'participants',
    'formValidation',
    'billingActive',
    'committeeType',
    'courseFormat',
    'locationNames',
    'category',
    'speaker',
    'starterSeminars',
    'startDate',
    'endDate',
    'activeFilter',
    'showHiddenFilter',
    'fullTextSearch',
    'filterValues',
    'currentPage'
];
