import { CollapsibleElements } from './modules/CollapsibleElements';
import domLoaded from '@/helper/domLoaded';

const collapsibleElements = new CollapsibleElements();

export default () => {
    domLoaded(() => {
        const timeout = setTimeout(() => {
            collapsibleElements.initialize();
            clearTimeout(timeout);
        }, 1500);
    });
};
