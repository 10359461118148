import { cookieService } from '@/helper/cookieService';

declare global {
    interface Window {
        googleMessId: string;
    }
}

const getGoogleMessId = () => {
    const appElement = <HTMLElement>document.querySelector('[data-google-mess-id]');

    if (!appElement) return;

    window.googleMessId = appElement.dataset.googleMessId;

    return appElement.dataset.googleMessId;
};

export default async (): Promise<void> => {
    const messId = getGoogleMessId();

    if (!messId) return;
    if (typeof gtag === 'undefined') return;

    gtag('js', new Date());
    gtag('config', messId);
};
