<template>
    <component :is="isComponent" :class="classes" v-bind="$attrs" v-on="$listeners">
        <slot />
    </component>
</template>

<script>
import H1 from './BaseHeadline/H1.vue';
import H2 from './BaseHeadline/H2.vue';
import H3 from './BaseHeadline/H3.vue';
import H4 from './BaseHeadline/H4.vue';
import H5 from './BaseHeadline/H5.vue';
import H6 from './BaseHeadline/H6.vue';

export default {
    name: 'BaseHeadline',

    components: {
        H1,
        H2,
        H3,
        H4,
        H5,
        H6
    },

    props: {
        size: {
            type: String,
            required: false,
            default: '1'
        },

        fake: {
            type: String,
            required: false
        },

        color: {
            type: String,
            required: false,
            default: 'text-gk-black'
        }
    },

    computed: {
        hSize() {
            return parseInt(this.size, 10);
        },

        hSizeFake() {
            return parseInt(this.fake, 10);
        },

        fontColor() {
            if (!this.color) return '';

            return this.color;
        },

        isComponent() {
            switch (this.hSize) {
                case 2:
                    return H2;

                case 3:
                    return H3;

                case 4:
                    return H4;

                case 5:
                    return H5;

                case 6:
                    return H6;

                default:
                    return H1;
            }
        },

        sizeClasses() {
            return ['text-h1', 'text-h2', 'text-h3', 'text-h4', 'text-h5', 'text-h6'];
        },

        fontSize() {
            if (this.fake) {
                return this.sizeClasses[this.hSizeFake - 1];
            }

            return '';
        },

        classes() {
            if (!this.fontColor && !this.fake) return null;

            return [this.fontSize, this.fontColor];
        }
    }
};
</script>
