export default () => {
    const images = document.getElementsByTagName('img');
    const elements = Array.from(images);

    elements.forEach((element: any) => {
        element.addEventListener(
            'contextmenu',
            function(ev: any) {
                ev.preventDefault();
                return false;
            },
            false
        );
    });
};
